import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { addNegativeTimezoneOffset, createNetworkErrorObject } from '../../../../../utils';
import Toast from '../../../../Shared/Toast/Toast';
import { Row } from '../../../../Shared/StyledComponents';
import { DoAppMerchant, ReferralCode } from '../../../../../store/DoApp/Merchants/types';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';
import { Grid, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import app from '../../../../../config/app/app';

type DoAppCreateOrUpdateMerchantReferralCodeProps = {
  open: boolean;
  merchant: DoAppMerchant;
  referralCode?: ReferralCode;
  onCloseClick: () => void;
  onSubmit: () => void;
  type: 'create' | 'update';
};

const DoAppCreateOrUpdateMerchantReferralCode: FunctionComponent<DoAppCreateOrUpdateMerchantReferralCodeProps> = ({
  open,
  merchant,
  referralCode,
  onCloseClick,
  onSubmit,
  type
}) => {
  const { t } = useTranslation();

  const [datePickersError, setDatePickersError] = useState<boolean>(false);
  const [updatedCode, setUpdatedCode] = useState(referralCode?.code || '');
  const [startDate, setStartDate] = useState<Date | null>(
    referralCode?.startDate ? addNegativeTimezoneOffset(referralCode.startDate) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    referralCode?.endDate ? addNegativeTimezoneOffset(referralCode.endDate) : null
  );

  useEffect(() => {
    if (open) {
      setUpdatedCode(referralCode?.code || '');
      setStartDate(referralCode?.startDate ? addNegativeTimezoneOffset(referralCode.startDate) : null);
      setEndDate(referralCode?.endDate ? addNegativeTimezoneOffset(referralCode.endDate) : null);
    }
  }, [open, referralCode]);

  const handleSubmit = async () => {
    try {
      const updatedStartDate = startDate ? addNegativeTimezoneOffset(startDate) : new Date();
      const updatedEndDate = endDate ? addNegativeTimezoneOffset(endDate) : null;

      if (type === 'create') {
        await doAppMerchantsOperations.createReferralCode(merchant.id, {
          code: updatedCode,
          startDate: updatedStartDate,
          endDate: updatedEndDate
        });
      } else if (type === 'update' && referralCode) {
        await doAppMerchantsOperations.updateReferralCode(merchant.id, referralCode.code, {
          code: updatedCode,
          startDate: updatedStartDate,
          endDate: updatedEndDate
        });
      }

      onCloseClick();
      await onSubmit();
      Toast.success(t('notifications.common.successOperation'));
    } catch (e) {
      const error = createNetworkErrorObject(e);
      Toast.error(error.message || t('notifications.common.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t(`pages.doApp.merchants.windows.createOrUpdateMerchantReferralCode.${type}Header`)}
      onCloseClick={onCloseClick}
      okButtonText={t(type === 'create' ? 'common.save' : 'common.update')}
      disableSubmitButton={!updatedCode || !startDate || datePickersError}
      onSubmit={handleSubmit}
    >
      <Row>
        <TextField
          label={t('pages.doApp.merchants.windows.createOrUpdateMerchantReferralCode.referralCode')}
          type="text"
          value={updatedCode}
          onChange={(e) => setUpdatedCode(e.target.value)}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Row>
      <Grid container spacing={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              label={t('pages.doApp.merchants.windows.createOrUpdateMerchantReferralCode.startDate')}
              format={app.dateFormats.datePickers.date}
              value={startDate}
              onChange={setStartDate}
              fullWidth
              inputVariant="outlined"
              size="small"
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              clearable
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              label={t('pages.doApp.merchants.windows.createOrUpdateMerchantReferralCode.endDate')}
              format={app.dateFormats.datePickers.date}
              value={endDate}
              onChange={setEndDate}
              fullWidth
              minDate={startDate || new Date()}
              inputVariant="outlined"
              onError={(err) => setDatePickersError(err ? true : false)}
              size="small"
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              clearable
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    </ModalConfirmationWindow>
  );
};

export default DoAppCreateOrUpdateMerchantReferralCode;
