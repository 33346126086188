import React, { FunctionComponent } from 'react';
import { FormTextInputProps } from './types';
import { Field, FieldProps } from 'formik';
import getFormInputError from './getFormInputError';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import app from '../../../config/app/app';
import moment from 'moment';

const FormTextInput: FunctionComponent<FormTextInputProps> = (props) => {
  const { formikFieldProps, textFieldProps, customErrorMessagePath, label, className } = props;
  const { t } = useTranslation();

  return (
    <Field name={formikFieldProps.name}>
      {(fieldProps: FieldProps) => {
        const { field, form } = fieldProps;
        const errorMessage = getFormInputError(fieldProps, t, customErrorMessagePath);

        const handleDateChange = (date: Date | null) => {
          form.setFieldTouched(field.name, true);
          form.setFieldValue(field.name, date ? moment(date).format(app.dateFormats.inputValueDate) : null);
        };

        const getPlaceholder = () => {
          if (!textFieldProps) return;

          return textFieldProps.required && textFieldProps.value
            ? `${textFieldProps.placeholder} *`
            : textFieldProps.placeholder;
        };

        if (textFieldProps?.type === 'date') {
          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {label}
              <KeyboardDatePicker
                fullWidth
                inputVariant="outlined"
                size="small"
                format={app.dateFormats.datePickers.date}
                label={textFieldProps.label}
                value={field.value || null}
                onChange={handleDateChange}
                onBlur={() => form.setFieldTouched(field.name, true)}
                error={errorMessage[0] || (textFieldProps && textFieldProps.required && !field.value)}
                helperText={
                  errorMessage[1] ||
                  (textFieldProps &&
                    textFieldProps.required &&
                    !field.value &&
                    t('formValidationErrors.genericRequired')) ||
                  ''
                }
                minDate={textFieldProps.inputProps ? textFieldProps.inputProps.min : undefined}
                maxDate={textFieldProps.inputProps ? textFieldProps.inputProps.max : undefined}
                clearable
              />
            </MuiPickersUtilsProvider>
          );
        }

        return (
          <>
            {label}
            <TextField
              {...field}
              classes={{
                root: className
              }}
              InputProps={{
                ...(textFieldProps?.inputProps as any),
                endAdornment: errorMessage[0] ? (
                  <InputAdornment position="end">
                    <ErrorOutline style={{ color: '#FF3300' }} />
                  </InputAdornment>
                ) : null
              }}
              {...textFieldProps}
              placeholder={getPlaceholder()}
              label={textFieldProps?.label}
              error={errorMessage[0] || (textFieldProps && textFieldProps.required && !field.value)}
              helperText={
                errorMessage[1] ||
                (textFieldProps &&
                  textFieldProps.required &&
                  !field.value &&
                  t('formValidationErrors.genericRequired')) ||
                ''
              }
            />
          </>
        );
      }}
    </Field>
  );
};

export default FormTextInput;
