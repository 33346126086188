import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { AdminKeyword, AdminKeywordGroup } from '../AdminKeywords/types';
import { DamSystem, PrintCenter } from '../SystemSettings/types';

export type AdminOrganizationsState = StateWithPagination &
  StateWithTableSearch<AdminOrganization> & {
    organizations: NormalizedAdminOrganizations;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedAdminOrganizations = {
  entities: Dictionary<AdminOrganization>;
  result: string[];
};

export enum OrganizationType {
  'accom_food' = 'accom_food',
  'arts_entertainment' = 'arts_entertainment',
  'finance_insurance' = 'finance_insurance',
  'health_social' = 'health_social',
  'non_profit_business' = 'non_profit_business',
  'non_profit_benevolent' = 'non_profit_benevolent',
  'prof_sci_tech' = 'prof_sci_tech',
  'real_estate_rental' = 'real_estate_rental',
  'retail_trade' = 'retail_trade'
}

export type AdminOrganizationsIndexResponse = {
  data: AdminOrganization[];
  pagination: Pagination;
};

export type AdminOrganizationsIndexActionBEGIN = {
  type: AdminOrganizationsActionsConstants.INDEX_BEGIN;
};

export type AdminOrganizationsIndexActionSUCCESS = {
  type: AdminOrganizationsActionsConstants.INDEX_SUCCESS;
  payload: AdminOrganizationsIndexResponse;
};

export type IndexAdminOrganizationsRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};
export type AdminCreateOrganizationValues = Pick<
  AdminOrganization,
  | 'name'
  | 'accountNumber'
  | 'passwordPolicyId'
  | 'injectLogoToPrintProjects'
  | 'stripProtocolFromPrintInjectedUrls'
  | 'socialPublishing'
  | 'colorSwatchesLimit'
  | 'bulletin'
  | 'email'
  | 'flyer'
  | 'newsletter'
  | 'directory'
  | 'emailWizardEnabled'
  | 'automatedEmailEnabled'
  | 'defaultAutomatedEmailTemplateId'
  | 'automatedEmailDateSchedule'
  | 'automatedEmailTime'
  | 'automatedEmailSubject'
  | 'senderEmail'
  | 'enableCampaigns'
  | 'region'
  | 'district'
> & { dam: null | { id: string; apiKey: string }; printCenters: { [key: string]: boolean } };

export type AdminUpdateOrganizationValues = Pick<
  AdminOrganization,
  | 'name'
  | 'accountNumber'
  | 'passwordPolicyId'
  | 'injectLogoToPrintProjects'
  | 'stripProtocolFromPrintInjectedUrls'
  | 'socialPublishing'
  | 'colorSwatchesLimit'
  | 'bulletin'
  | 'email'
  | 'flyer'
  | 'newsletter'
  | 'directory'
  | 'readMoreLink'
  | 'emailWizardEnabled'
  | 'automatedEmailEnabled'
  | 'defaultAutomatedEmailTemplateId'
  | 'automatedEmailDateSchedule'
  | 'automatedEmailTime'
  | 'automatedEmailSubject'
  | 'defaultAutomatedEmailMailingListId'
  | 'senderEmail'
  | 'parentId'
  | 'enableCampaigns'
  | 'region'
  | 'district'
> & { dam: null | { id: string; apiKey: string }; printCenters: { [key: string]: boolean } };

export type AdminOrganizationsIndexActionFAILED = {
  type: AdminOrganizationsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type AdminOrganizationOwner = {
  id: string;
  email: string;
  fullName: string;
};

export type AdminOrganization = {
  id: string;
  name: string;
  type: OrganizationType | null;
  advertiserId: string | null;
  accountNumber: string;
  passwordPolicyId: string;
  uniqAdminOrganization: 'uniqAdminOrganization';
  createdAt: string;
  updatedAt: string;
  injectLogoToPrintProjects: boolean;
  stripProtocolFromPrintInjectedUrls: boolean;
  socialPublishing: boolean;
  bulletin: boolean;
  email: boolean;
  flyer: boolean;
  newsletter: boolean;
  directory: boolean;
  emailWizardEnabled: boolean;
  automatedEmailEnabled: boolean;
  automatedEmailDateSchedule: string | null;
  automatedEmailTime: string | null;
  automatedEmailSubject: string | null;
  defaultAutomatedEmailTemplateId: string | null;
  colorSwatchesLimit: number | null;
  readMoreLink: string | null;
  defaultAutomatedEmailMailingListId: string | null;
  keywords: AdminKeyword[];
  keywordGroups: AdminKeywordGroup[];
  defaultAutomatedEmailMailingList: {
    name: string;
  } | null;
  senderEmail: string | null;
  parentId: string | null;
  masterOrgId: string | null;
  owners: AdminOrganizationOwner[];
  damIntegrations: DamIntegration[];
  facebookLink: string | null;
  linkedinLink: string | null;
  instagramLink: string | null;
  twitterLink: string | null;
  printCenters: PrintCenter[];
  youtubeLink: string | null;
  enableCampaigns: boolean;
  region: string | null;
  district: string | null;
  websiteLink: string | null;
  isDistrictOrRegion: boolean;
};

export type AdminOrganizationShort = Pick<
  AdminOrganization,
  'id' | 'accountNumber' | 'createdAt' | 'updatedAt' | 'name' | 'parentId' | 'masterOrgId' | 'owners'
>;

export type OrganizationAdZone = {
  id: string;
  createdAt: string;
  zoneId: string;
  organizationId: string;
  size: string;
  adZoneLink: string | null;
  adClickLink: string | null;
};

export type DamIntegration = {
  id: string;
  createdAt: string;
  updatedAt: string;
  systemId: string;
  apiKey: string;
  organizationId: string;
  system?: DamSystem;
};

export type AdminOrganizationsResponse = {
  data: AdminOrganization[];
};

export type AdminOrganizationsKeywordAddedAction = {
  type: AdminOrganizationsActionsConstants.KEYWORD_ADDED;
  payload: AdminOrganization;
};

export type AdminOrganizationsKeywordGroupAddedAction = {
  type: AdminOrganizationsActionsConstants.KEYWORD_GROUP_ADDED;
  payload: AdminOrganization;
};

export type AdminOrganizationsKeywordRemovedAction = {
  type: AdminOrganizationsActionsConstants.KEYWORD_REMOVED;
  payload: AdminOrganization;
};

export type AdminOrganizationsKeywordGroupRemovedAction = {
  type: AdminOrganizationsActionsConstants.KEYWORD_GROUP_REMOVED;
  payload: AdminOrganization;
};

export enum AdminOrganizationsActionsConstants {
  KEYWORD_ADDED = 'ADMIN_ORGANIZATIONS/KEYWORD_ADDED',
  KEYWORD_REMOVED = 'ADMIN_ORGANIZATIONS/KEYWORD_REMOVED',
  KEYWORD_GROUP_ADDED = 'ADMIN_ORGANIZATIONS/KEYWORD_GROUP_ADDED',
  KEYWORD_GROUP_REMOVED = 'ADMIN_ORGANIZATIONS/KEYWORD_GROUP_REMOVED',
  INDEX_BEGIN = 'ADMIN_ORGANIZATIONS/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMIN_ORGANIZATIONS/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMIN_ORGANIZATIONS/INDEX/FAILED'
}

export type AdminOrganizationsActionType =
  | AdminOrganizationsKeywordAddedAction
  | AdminOrganizationsKeywordRemovedAction
  | AdminOrganizationsKeywordGroupAddedAction
  | AdminOrganizationsKeywordGroupRemovedAction
  | AdminOrganizationsIndexActionBEGIN
  | AdminOrganizationsIndexActionSUCCESS
  | AdminOrganizationsIndexActionFAILED;
