import moment from 'moment';

export const validateUrl = (str: string) => {
  const pattern = new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
  );
  return pattern.test(str);
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isStartDateBeforeOrEqualEndDate = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) return true; // Skip validation if one of the dates is missing
  return moment(startDate).isSameOrBefore(moment(endDate));
};
