import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Button,
  Box
} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import { DoAppMerchant, ReferralCode, ReferralCodeStatus } from '../../../../../store/DoApp/Merchants/types';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import DoAppChangeMerchantReferralCodeStatus from './DoAppChangeMerchantReferralCodeStatus';
import { formatPublishDate } from '../../../../../utils';
import DoAppCreateOrUpdateMerchantReferralCode from './DoAppCreateOrUpdateMerchantReferralCode';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

type DoAppMerchantReferralCodesProps = {
  open: boolean;
  merchant: DoAppMerchant;
  onCloseClick: () => void;
  onReferralCodeUpdate: () => void;
  onReferralCodeStatusChange: () => void;
};

const DoAppMerchantReferralCodes: FunctionComponent<DoAppMerchantReferralCodesProps> = ({
  open,
  merchant,
  onCloseClick,
  onReferralCodeUpdate,
  onReferralCodeStatusChange
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [activeMerchantReferralCode, setActiveMerchantReferralCode] = useState<ReferralCode | null>(null);
  const [referralCodes, setReferralCodes] = useState<ReferralCode[]>([]);
  const [
    changeReferralCodeStatusWindowOpen,
    onChangeReferralCodeStatusWindowOpen,
    onChangeReferralCodeStatusWindowClose
  ] = useOpenHandler();
  const [
    updateReferralCodeWindowOpen,
    onUpdateReferralCodeWindowOpen,
    onUpdateReferralCodeWindowClose
  ] = useOpenHandler();
  const [
    createReferralCodeWindowOpen,
    onCreateReferralCodeWindowOpen,
    onCreateReferralCodeWindowClose
  ] = useOpenHandler();

  if (!merchant) {
    return null;
  }

  const fetchStatisticsData = async () => {
    try {
      setLoading(true);
      const res = await doAppMerchantsOperations.getMerchantReferralsStatistics(merchant.id);
      setReferralCodes(res.data);
    } catch (e) {
      console.log('Error fetching referral codes', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatisticsData();
  }, []);

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.merchants.windows.merchantReferralCodesWindow.header')}
      onCloseClick={onCloseClick}
      hideActionButtons
      maxWidth="lg"
    >
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('pages.doApp.merchants.windows.merchantReferralCodesWindow.referralCode')}</TableCell>
              <TableCell>{t('pages.doApp.merchants.windows.merchantReferralCodesWindow.startDate')}</TableCell>
              <TableCell>{t('pages.doApp.merchants.windows.merchantReferralCodesWindow.endDate')}</TableCell>
              <TableCell>{t('pages.doApp.merchants.windows.merchantReferralCodesWindow.accepted')}</TableCell>
              <TableCell>{t('pages.doApp.merchants.windows.merchantReferralCodesWindow.pending')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {merchant.referralCodes.map((referralCode) => (
              <TableRow key={referralCode.code}>
                <TableCell component="th" scope="row">
                  <span
                    style={{
                      textDecoration: referralCode.status === ReferralCodeStatus.ACTIVE ? 'none' : 'line-through'
                    }}
                  >
                    {referralCode.code}
                  </span>
                </TableCell>
                <TableCell component="th" scope="row">
                  {referralCode.startDate ? formatPublishDate(referralCode.startDate) : '-'}
                </TableCell>
                <TableCell component="th" scope="row">
                  {referralCode.endDate ? (
                    formatPublishDate(referralCode.endDate)
                  ) : (
                    <AllInclusiveIcon fontSize="small" />
                  )}
                </TableCell>
                <TableCell align="right">
                  {(!loading && referralCodes.find((rc) => rc.code === referralCode.code)?.usage?.accepted) || 0}
                </TableCell>
                <TableCell align="right">
                  {(!loading && referralCodes.find((rc) => rc.code === referralCode.code)?.usage?.pending) || 0}
                </TableCell>
                <TableCell align="right">
                  {referralCode.status === ReferralCodeStatus.ACTIVE && (
                    <>
                      <Tooltip title={t('pages.doApp.merchants.updateReferralCode').toString()}>
                        <IconButton
                          size="small"
                          disableRipple
                          onClick={() => {
                            setActiveMerchantReferralCode(referralCode);
                            onUpdateReferralCodeWindowOpen();
                          }}
                        >
                          <EditOutlinedIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('pages.doApp.merchants.revokeReferralCode').toString()}>
                        <IconButton
                          size="small"
                          disableRipple
                          onClick={() => {
                            setActiveMerchantReferralCode(referralCode);
                            onChangeReferralCodeStatusWindowOpen();
                          }}
                        >
                          <DeleteForeverOutlinedIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {referralCode.status === ReferralCodeStatus.REVOKED && (
                    <Tooltip title={t('pages.doApp.merchants.restoreReferralCode').toString()}>
                      <IconButton
                        size="small"
                        disableRipple
                        onClick={() => {
                          setActiveMerchantReferralCode(referralCode);
                          onChangeReferralCodeStatusWindowOpen();
                        }}
                      >
                        <UndoOutlinedIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box textAlign="right" mt={1}>
          <Button variant="contained" size="small" color="secondary" onClick={onCreateReferralCodeWindowOpen}>
            {t('pages.doApp.merchants.addReferralCode')}
          </Button>
        </Box>
      </TableContainer>
      {activeMerchantReferralCode && (
        <DoAppChangeMerchantReferralCodeStatus
          open={changeReferralCodeStatusWindowOpen}
          merchant={merchant}
          referralCode={activeMerchantReferralCode}
          onCloseClick={onChangeReferralCodeStatusWindowClose}
          onSubmit={onReferralCodeStatusChange}
        />
      )}
      {activeMerchantReferralCode && (
        <DoAppCreateOrUpdateMerchantReferralCode
          open={updateReferralCodeWindowOpen}
          merchant={merchant}
          referralCode={activeMerchantReferralCode}
          onCloseClick={onUpdateReferralCodeWindowClose}
          onSubmit={onReferralCodeUpdate}
          type="update"
        />
      )}
      {createReferralCodeWindowOpen && (
        <DoAppCreateOrUpdateMerchantReferralCode
          open={createReferralCodeWindowOpen}
          merchant={merchant}
          onCloseClick={onCreateReferralCodeWindowClose}
          onSubmit={onReferralCodeUpdate}
          type="create"
        />
      )}
    </ModalConfirmationWindow>
  );
};

export default DoAppMerchantReferralCodes;
