import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { createNetworkErrorObject } from '../../../../../utils';
import Toast from '../../../../Shared/Toast/Toast';
import { Row } from '../../../../Shared/StyledComponents';
import { DoAppMerchant, ReferralCode, ReferralCodeStatus } from '../../../../../store/DoApp/Merchants/types';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';

type DoAppChangeMerchantReferralCodeStatusProps = {
  open: boolean;
  merchant: DoAppMerchant;
  referralCode: ReferralCode;
  onCloseClick: () => void;
  onSubmit: () => void;
};

const DoAppChangeMerchantReferralCodeStatus: FunctionComponent<DoAppChangeMerchantReferralCodeStatusProps> = ({
  open,
  merchant,
  referralCode,
  onCloseClick,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t(`pages.doApp.merchants.windows.changeMerchantReferralCodeStatus.${referralCode.status}Header`)}
      onCloseClick={onCloseClick}
      okButtonText={t(`pages.doApp.merchants.windows.changeMerchantReferralCodeStatus.${referralCode.status}`)}
      onSubmit={async () => {
        try {
          await doAppMerchantsOperations.updateReferralCode(merchant.id, referralCode.code, {
            status:
              referralCode.status === ReferralCodeStatus.ACTIVE ? ReferralCodeStatus.REVOKED : ReferralCodeStatus.ACTIVE
          });
          onCloseClick();
          await onSubmit();
          Toast.success(t('notifications.common.successOperation'));
        } catch (e) {
          const error = createNetworkErrorObject(e);
          if (error.message) {
            Toast.error(error.message);
          } else {
            Toast.error(t('notifications.common.errorOperation'));
          }
        }
      }}
    >
      <Row>{t(`pages.doApp.merchants.windows.changeMerchantReferralCodeStatus.${referralCode.status}Content`)}</Row>
      <Row>
        <strong>{t('pages.doApp.merchants.windows.changeMerchantReferralCodeStatus.referralCode')}:</strong>{' '}
        {referralCode.code}
      </Row>
      <Row>
        <strong>{t('pages.doApp.merchants.windows.changeMerchantReferralCodeStatus.merchant')}:</strong>{' '}
        {merchant.email}
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DoAppChangeMerchantReferralCodeStatus;
