import React from 'react';
import { IconButton, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

type WidgetSearchInputProps = {
  placeholder: string;
  value: string;
  setSearchValue: (val: string) => void;
  handleSearch: (page: number, search: string) => void;
};

export const WidgetSearchInput = (props: WidgetSearchInputProps) => {
  return (
    <div style={{ display: 'flex', minWidth: 260, alignItems: 'center' }}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.setSearchValue(e.target.value)}
        InputProps={{
          endAdornment: props.value !== '' && (
            <IconButton
              color={'primary'}
              size="small"
              onClick={() => {
                props.setSearchValue('');
                props.handleSearch(1, '');
              }}
            >
              <ClearIcon />
            </IconButton>
          )
        }}
      />
      <IconButton color={'secondary'} size="medium" onClick={() => props.handleSearch(1, props.value)}>
        <SearchIcon />
      </IconButton>
    </div>
  );
};
