import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../../components/Shared/DataTable/types';

export enum DoAppPromotionRangeQuery {
  ALL = 'all',
  ACTIVE = 'active',
  UPCOMING = 'upcoming',
  EXPIRED = 'expired'
}

export enum DoAppPromotionStatus {
  ACTIVE = 'active',
  UPCOMING = 'upcoming',
  EXPIRED = 'expired'
}

export type DoAppPromotion = {
  id: string;
  createdAt: Date;
  name: string;
  startDate: Date;
  endDate: Date;
  merchant: {
    id: string;
    name: string;
    region: string;
    district: string;
  };
  merchantPortalId: string;
  status: DoAppPromotionStatus;
};

export type DoAppPromotionsIndexResponse = {
  data: DoAppPromotion[];
  pagination: Pagination;
};

export type Claim = {
  accepted: number;
  pending: number;
  rejected: number;
};

export type ClaimsCollection = {
  [claimId: string]: Claim;
};

export type DoAppPromotionsMineIndexResponse = {
  data: DoAppPromotion[];
  pagination: Pagination;
  meta: {
    claims: ClaimsCollection;
  };
};

export type DoAppMerchantPromotionsIndexResponse = {
  data: DoAppPromotion[];
  pagination: Pagination;
};

export type IndexDoAppPromotionsRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export type IndexDoAppPromotionsMineRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
  range?: DoAppPromotionRangeQuery;
};

export type DoAppMerchantPromotionsState = StateWithPagination &
  StateWithTableSearch<DoAppPromotion> & {
    data: NormalizedDoAppMerchantPromotions;
    meta: {
      claims: ClaimsCollection;
    };
    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedDoAppMerchantPromotions = {
  entities: Dictionary<DoAppPromotion>;
  result: string[];
};

export type DoAppMerchantPromotionsIndexActionBEGIN = {
  type: DoAppMerchantPromotionsActionsConstants.INDEX_BEGIN;
};

export type DoAppMerchantPromotionsIndexActionSUCCESS = {
  type: DoAppMerchantPromotionsActionsConstants.INDEX_SUCCESS;
  payload: DoAppPromotionsMineIndexResponse;
};

export type DoAppMerchantPromotionsIndexActionFAILED = {
  type: DoAppMerchantPromotionsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export enum DoAppMerchantPromotionsActionsConstants {
  INDEX_BEGIN = 'DO_APP/MERCHANT_PROMOTIONS/INDEX/BEGIN',
  INDEX_SUCCESS = 'DO_APP/MERCHANT_PROMOTIONS/INDEX/SUCCESS',
  INDEX_FAILED = 'DO_APP/MERCHANT_PROMOTIONS/INDEX/FAILED'
}

export type DoAppMerchantPromotionsActionType =
  | DoAppMerchantPromotionsIndexActionBEGIN
  | DoAppMerchantPromotionsIndexActionSUCCESS
  | DoAppMerchantPromotionsIndexActionFAILED;
