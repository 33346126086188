import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppPromotion } from '../../../../../store/DoApp/Promotions/types';
import { doAppPromotionsOperations } from '../../../../../store/DoApp/Promotions';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import { createNetworkErrorObject, formatPublishDate } from '../../../../../utils';
import { Strong } from '../../../../Shared/StyledComponents';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import { storiesOperations } from '../../../../../store/Stories';
import { AdminOrganizationShort } from '../../../../../store/AdminOrganizations/types';
import Toast from '../../../../Shared/Toast/Toast';

type DoAppPromotionsListProps = {};

const DoAppPromotionsList: FunctionComponent<DoAppPromotionsListProps> = () => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [promotions, setPromotions] = useState<DoAppPromotion[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });
  const [regionDistricts, setRegionDistricts] = useState<
    {
      region: AdminOrganizationShort;
      districts: AdminOrganizationShort[];
    }[]
  >([]);

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const promotions = await doAppPromotionsOperations.indexRaw(page, 10, search, {});
      setPromotions(promotions.data);
      setPagination(promotions.pagination);
      setLastSearchValue(search);

      // Extract unique regions
      const uniqueRegions = Array.from(new Set(promotions.data.map((p) => p.merchant.region)));

      // Fetch region and district details
      const regionData: { region: AdminOrganizationShort; districts: AdminOrganizationShort[] }[] = [];

      for (const region of uniqueRegions) {
        try {
          const data = await storiesOperations.getRegionDistricts(region);
          regionData.push({
            region: data.region,
            districts: data.districts
          });
        } catch (error) {
          const networkError = createNetworkErrorObject(error);
          if (networkError.statusCode !== 404) {
            console.log(networkError);
          }
        }
      }

      setRegionDistricts(regionData);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.key === 'Enter') {
        fetchData(1, searchValue);
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [searchValue]);

  const renderList = () => {
    return (
      <>
        <WidgetTitle
          title={t('pages.doApp.promotions.title')}
          subtitle={t('pages.doApp.promotions.subtitle')}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          fetchData={fetchData}
        />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        {!loading && fetchError && <WidgetFetchError title={t('pages.doApp.promotions.fetchError')} />}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('pages.doApp.promotions.name')}</TableCell>
                <TableCell>{t('pages.doApp.promotions.merchant')}</TableCell>
                <TableCell>{t('pages.doApp.promotions.region')}</TableCell>
                <TableCell>{t('pages.doApp.promotions.district')}</TableCell>
                <TableCell>{t('pages.doApp.promotions.dates')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promotions.map((promotion) => {
                const regionData = regionDistricts.find((rd) => rd.region.accountNumber === promotion.merchant.region);

                const regionName = regionData?.region.name;
                const districtName = regionData?.districts.find((d) => d.accountNumber === promotion.merchant.district)
                  ?.name;

                return (
                  <TableRow key={promotion.id}>
                    <TableCell>
                      <Strong>{promotion.name}</Strong>
                      <Box display="flex" alignItems="center">
                        <ConfirmationNumberOutlinedIcon style={{ width: 14, height: 14, marginRight: 4 }} />{' '}
                        {promotion.merchantPortalId}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {promotion.merchant.name}
                      <Box display="flex" alignItems="center">
                        <AccountBoxOutlinedIcon style={{ width: 14, height: 14, marginRight: 4 }} />{' '}
                        {promotion.merchant.id}
                      </Box>
                    </TableCell>
                    <TableCell>{regionName || promotion.merchant.region}</TableCell>
                    <TableCell>{districtName || promotion.merchant.district}</TableCell>
                    <TableCell>{`${formatPublishDate(promotion.startDate)} - ${formatPublishDate(
                      promotion.endDate
                    )}`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>
      </>
    );
  };

  return renderList();
};

export default DoAppPromotionsList;
