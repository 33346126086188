import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { DoAppSubscriber } from '../../../../../store/DoApp/Subscribers/types';
import { createNetworkErrorObject } from '../../../../../utils';
import Toast from '../../../../Shared/Toast/Toast';
import { Row } from '../../../../Shared/StyledComponents';
import { doAppSubscribersOperations } from '../../../../../store/DoApp/Subscribers';

type DoAppRemoveSubscriberDeviceIdProps = {
  open: boolean;
  subscriber: DoAppSubscriber;
  deviceId: string;
  onCloseClick: () => void;
  onSubmit: () => void;
};

const DoAppRemoveSubscriberDeviceId: FunctionComponent<DoAppRemoveSubscriberDeviceIdProps> = ({
  open,
  subscriber,
  deviceId,
  onCloseClick,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.subscribers.removeSubscriberDeviceIdWindow.header')}
      onCloseClick={onCloseClick}
      okButtonText={t('common.remove')}
      onSubmit={async () => {
        try {
          await doAppSubscribersOperations.removeSubscriberDeviceId(subscriber.id, deviceId);
          onCloseClick();
          await onSubmit();
          Toast.success(t('notifications.common.successOperation'));
        } catch (e) {
          const error = createNetworkErrorObject(e);
          if (error.message) {
            Toast.error(error.message);
          } else {
            Toast.error(t('notifications.common.errorOperation'));
          }
        }
      }}
    >
      <Row>{t('pages.doApp.subscribers.removeSubscriberDeviceIdWindow.content')}</Row>
      <Row>
        <strong>{t('pages.doApp.subscribers.removeSubscriberDeviceIdWindow.deviceId')}:</strong> {deviceId}
      </Row>
      <Row>
        <strong>{t('pages.doApp.subscribers.removeSubscriberDeviceIdWindow.subscriber')}:</strong> {subscriber.email}
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DoAppRemoveSubscriberDeviceId;
