import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { NoResultsFound } from '../../PageStyledComponents';
import { AdBanner } from '../../../../store/AdminAds/types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { DataTableHeaderCell } from '../../../Shared/DataTable/DataTableStyled';
import Loader from '../../../Shared/Loading/Loader';
import AdBannerTableRow from './Partials/AdBannerTableRow';

type AdminAdsTableProps = {
  isLoading: boolean;
  banners: AdBanner[];
  onRemoveAdBanner: (banner: AdBanner) => void;
  onUpdateAdBanner: (banner: AdBanner) => void;
  showStartDate?: boolean;
  showEndDate?: boolean;
  elevation?: number;
};

const AdminAdsTable: FunctionComponent<AdminAdsTableProps> = ({
  banners,
  isLoading,
  onUpdateAdBanner,
  onRemoveAdBanner,
  showStartDate = true,
  showEndDate = true,
  elevation = 1
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && banners.length === 0 && <NoResultsFound>{t('pages.adminAds.noBanners')}</NoResultsFound>}
      {!isLoading && banners.length > 0 && (
        <TableContainer component={Paper} elevation={elevation}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '24px' }} />
                <TableCell>
                  <DataTableHeaderCell>{t('pages.adminAds.table.name')}</DataTableHeaderCell>
                </TableCell>
                <TableCell>
                  <DataTableHeaderCell>{t('pages.adminAds.table.actions')}</DataTableHeaderCell>
                </TableCell>
                <TableCell>
                  <DataTableHeaderCell>{t('pages.adminAds.table.size')}</DataTableHeaderCell>
                </TableCell>
                <TableCell>
                  <DataTableHeaderCell>{t('pages.adminAds.table.paid')}</DataTableHeaderCell>
                </TableCell>
                {showStartDate && (
                  <TableCell>
                    <DataTableHeaderCell>{t('pages.adminAds.table.startDate')}</DataTableHeaderCell>
                  </TableCell>
                )}
                {showEndDate && (
                  <TableCell>
                    <DataTableHeaderCell>{t('pages.adminAds.table.endDate')}</DataTableHeaderCell>
                  </TableCell>
                )}
                <TableCell>
                  <DataTableHeaderCell>{t('pages.adminAds.table.url')}</DataTableHeaderCell>
                </TableCell>
                <TableCell>
                  <DataTableHeaderCell>{t('pages.adminAds.table.modifiedDate')}</DataTableHeaderCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {banners.map((banner) => (
                <AdBannerTableRow
                  key={banner.bannerId}
                  context={'adsAdministration'}
                  banner={banner}
                  onRemoveAdBanner={onRemoveAdBanner}
                  onUpdateAdBanner={onUpdateAdBanner}
                  showStartDate={showStartDate}
                  showEndDate={showEndDate}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default AdminAdsTable;
