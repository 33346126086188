import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import { doAppMerchantsOperations } from '../../../../store/DoApp/Merchants';
import {
  DoAppMerchant,
  GetMerchantResponse,
  MerchantReward,
  MerchantRewardDescription
} from '../../../../store/DoApp/Merchants/types';
import Loader from '../../../Shared/Loading/Loader';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
  useTheme
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { Alert } from '@material-ui/lab';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import DeleteIncentiveCreditsWindow from './DeleteIncentiveCreditsWindow';

type MerchantRewardsWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  type: 'incentive' | 'promotional';
  organizationMerchant?: DoAppMerchant;
  removeIncentiveCredits?: boolean;
  onRemoveIncentiveCredits?: () => void;
};

const MerchantRewardsWindow: FunctionComponent<MerchantRewardsWindowProps> = ({
  onCloseClick,
  open,
  type,
  organizationMerchant,
  removeIncentiveCredits,
  onRemoveIncentiveCredits
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [merchatnRewards, setMerchantRewards] = useState<MerchantReward[]>([]);
  const [activeReward, setActiveReward] = useState<MerchantReward | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [
    deleteIncentiveCreditsWindowOpen,
    onDeleteIncentiveCreditsWindowOpen,
    onDeleteIncentiveCreditsWindowClose
  ] = useOpenHandler();

  const rewardDescriptions = {
    incentive: [
      MerchantRewardDescription.INCENTIVE_CREDITS_ADDED_MANUALLY,
      MerchantRewardDescription.INCENTIVE_CREDITS_FROM_SPONSOR,
      MerchantRewardDescription.INCENTIVE_CREDITS_USED_ON_INVOICE,
      MerchantRewardDescription.INCENTIVE_CREDITS_FOR_SUBSCRIBER_REFERRAL
    ],
    promotional: [
      MerchantRewardDescription.PROMOTIONAL_CREDITS_REDEEMED,
      MerchantRewardDescription.PROMOTIONAL_CREDITS_USED_ON_INVOICE,
      MerchantRewardDescription.PROMOTIONAL_CREDITS_FOR_SUBSCRIBER_REFERRAL
    ]
  };

  const fetchData = async () => {
    try {
      setError(null);

      let response: GetMerchantResponse;
      if (organizationMerchant) {
        response = await doAppMerchantsOperations.getOrganizationMerchantByAccountNumber(
          organizationMerchant.merchantPortalId
        );
      } else {
        response = await doAppMerchantsOperations.getMyRewards();
      }
      const rewards = response.data.rewards || [];

      setMerchantRewards(
        rewardDescriptions[type]
          ? rewards.filter((reward) => rewardDescriptions[type].includes(reward.description))
          : rewards
      );
    } catch (e) {
      setError(t('pages.myOrganization.merchant.couldNotFetchRewards'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getRewardDescription = (description: MerchantRewardDescription) => {
    switch (description) {
      case MerchantRewardDescription.INCENTIVE_CREDITS_ADDED_MANUALLY:
        return t('pages.myOrganization.windows.merchantRewards.incentiveCreditsAddedManually');
      case MerchantRewardDescription.INCENTIVE_CREDITS_FOR_SUBSCRIBER_REFERRAL:
      case MerchantRewardDescription.PROMOTIONAL_CREDITS_FOR_SUBSCRIBER_REFERRAL:
        return t('pages.myOrganization.windows.merchantRewards.incentiveCreditsForSubscriberReferral');
      case MerchantRewardDescription.INCENTIVE_CREDITS_FROM_SPONSOR:
        return t('pages.myOrganization.windows.merchantRewards.incentiveCreditsFromSponsor');
      case MerchantRewardDescription.INCENTIVE_CREDITS_USED_ON_INVOICE:
        return t('pages.myOrganization.windows.merchantRewards.incentiveCreditsUsedOnInvoice');
      case MerchantRewardDescription.PROMOTIONAL_CREDITS_REDEEMED:
        return t('pages.myOrganization.windows.merchantRewards.promotionalCreditsRedeemed');
      case MerchantRewardDescription.PROMOTIONAL_CREDITS_USED_ON_INVOICE:
        return t('pages.myOrganization.windows.merchantRewards.promotionalCreditsUsedOnInvoice');
      default:
        return t('pages.myOrganization.windows.merchantRewards.unknownReward');
    }
  };

  const getStyledRewardAmount = (amount: number, description: MerchantRewardDescription) => {
    const amountString = (amount / 100).toFixed(2);

    switch (description) {
      case MerchantRewardDescription.INCENTIVE_CREDITS_ADDED_MANUALLY:
      case MerchantRewardDescription.INCENTIVE_CREDITS_FROM_SPONSOR:
      case MerchantRewardDescription.INCENTIVE_CREDITS_FOR_SUBSCRIBER_REFERRAL:
      case MerchantRewardDescription.PROMOTIONAL_CREDITS_FOR_SUBSCRIBER_REFERRAL:
        return <Strong style={{ color: theme.palette.success.main }}>+${amountString}</Strong>;
      case MerchantRewardDescription.INCENTIVE_CREDITS_USED_ON_INVOICE:
      case MerchantRewardDescription.PROMOTIONAL_CREDITS_REDEEMED:
      case MerchantRewardDescription.PROMOTIONAL_CREDITS_USED_ON_INVOICE:
        return <Strong style={{ color: theme.palette.error.main }}>-${amountString}</Strong>;
      default:
        return <Strong>${amountString}</Strong>;
    }
  };

  const getRewardIcon = (description: MerchantRewardDescription) => {
    const iconStyle = { marginRight: 8, width: 20, height: 20, verticalAlign: 'middle' };

    switch (description) {
      case MerchantRewardDescription.INCENTIVE_CREDITS_ADDED_MANUALLY:
      case MerchantRewardDescription.INCENTIVE_CREDITS_FOR_SUBSCRIBER_REFERRAL:
      case MerchantRewardDescription.PROMOTIONAL_CREDITS_FOR_SUBSCRIBER_REFERRAL:
        return <TrendingUpIcon style={iconStyle} htmlColor={theme.palette.success.main} />;
      case MerchantRewardDescription.INCENTIVE_CREDITS_FROM_SPONSOR:
        return <PersonAddOutlinedIcon style={iconStyle} htmlColor={theme.palette.success.main} />;
      case MerchantRewardDescription.INCENTIVE_CREDITS_USED_ON_INVOICE:
        return <ReceiptOutlinedIcon style={iconStyle} />;
      case MerchantRewardDescription.PROMOTIONAL_CREDITS_REDEEMED:
        return <RedeemOutlinedIcon style={iconStyle} />;
      case MerchantRewardDescription.PROMOTIONAL_CREDITS_USED_ON_INVOICE:
        return <ReceiptOutlinedIcon style={iconStyle} />;
      default:
        return <HelpOutlineOutlinedIcon style={iconStyle} />;
    }
  };

  // Group rewards by created date (using locale date string)
  const groupedRewards = merchatnRewards.reduce((groups: Record<string, MerchantReward[]>, reward) => {
    const dateKey = new Date(reward.createdAt).toLocaleDateString();
    if (!groups[dateKey]) {
      groups[dateKey] = [];
    }
    groups[dateKey].push(reward);
    return groups;
  }, {});

  return (
    <>
      <ModalConfirmationWindow
        open={open}
        header={t(`pages.myOrganization.windows.merchantRewards.title.${type}`)}
        hideActionButtons
        onCloseClick={onCloseClick}
      >
        {loading && <Loader />}
        {!loading && error && <Row>{error}</Row>}
        {!loading && !error && merchatnRewards.length === 0 && (
          <Row>
            <Alert severity="info">{t(`pages.myOrganization.windows.merchantRewards.noRewards.${type}`)}</Alert>
          </Row>
        )}
        {!loading && !error && merchatnRewards.length > 0 && (
          <List dense>
            {Object.keys(groupedRewards)
              // Sort dates in descending order (most recent first)
              .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
              .map((date) => (
                <React.Fragment key={date}>
                  <ListSubheader
                    disableSticky
                    style={{
                      backgroundColor: theme.palette.background.default,
                      paddingTop: 0,
                      paddingBottom: 0,
                      height: 46
                    }}
                  >
                    <Strong>
                      <CalendarTodayIcon style={{ marginRight: 8, width: 12, height: 12 }} />
                      {date}
                    </Strong>
                  </ListSubheader>
                  {groupedRewards[date].map((reward, idx) => (
                    <React.Fragment key={`reward-${date}-${idx}`}>
                      <ListItem>
                        <ListItemIcon style={{ minWidth: 40 }}>{getRewardIcon(reward.description)}</ListItemIcon>
                        <ListItemText
                          primary={getRewardDescription(reward.description)}
                          secondary={
                            <div>
                              <div>
                                <Strong style={{ fontSize: 16 }}>
                                  {getStyledRewardAmount(reward.amount, reward.description)}
                                </Strong>
                              </div>
                              {removeIncentiveCredits && (
                                <div>
                                  <em>{reward.note}</em>
                                </div>
                              )}
                            </div>
                          }
                        />
                        {removeIncentiveCredits &&
                          reward.description === MerchantRewardDescription.INCENTIVE_CREDITS_ADDED_MANUALLY && (
                            <ListItemSecondaryAction>
                              <Tooltip
                                title={t(
                                  'pages.myOrganization.windows.merchantRewards.removeIncentiveCredits'
                                ).toString()}
                              >
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => {
                                    setActiveReward(reward);
                                    onDeleteIncentiveCreditsWindowOpen();
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          )}
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
          </List>
        )}
      </ModalConfirmationWindow>
      {deleteIncentiveCreditsWindowOpen && activeReward && organizationMerchant && (
        <DeleteIncentiveCreditsWindow
          open={deleteIncentiveCreditsWindowOpen}
          organizationMerchant={organizationMerchant}
          reward={activeReward}
          onCloseClick={onDeleteIncentiveCreditsWindowClose}
          onSubmit={() => {
            if (onRemoveIncentiveCredits) {
              onRemoveIncentiveCredits();
              fetchData();
            }
          }}
          fullScreenOnMobile={false}
        />
      )}
    </>
  );
};

export default MerchantRewardsWindow;
