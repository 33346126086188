import * as httpRequest from './httpRequest';
import sleep from './sleep';
import useTypedSelector from './useTypedSelector';
import createNetworkErrorObject from './createNetworkErrorObject';
import { getLocationPaginationState } from './getLocationPaginationState';
import {
  formatLpiExpressDate,
  formatNoteDate,
  formatPublishDate,
  formatTableTimestamp,
  formatTableTimestampWithHours,
  getUtcDate,
  insertTrailingZero,
  timestampToTime,
  timeToTimestamp,
  addNegativeTimezoneOffset
} from './dates';
import { isEmailProject, isPrintProject, isStory } from './projectTypeUtils';
import { closeFullscreen, openFullscreen } from './fullscreen';
import { limitString } from './limitString';
import { trimHtml } from './trimHtml';
import { getRoleNameElement } from './getRoleNameElement';
import { getPasswordExpirationData } from './getPasswordExpirationData';
import { validateUrl } from './validators';
import { createOrderArray } from './createOrderArray';
import { toCamelCase } from './toCamelCase';
import { getBase64 } from './base64';
import * as userLatestActivity from './userLatestActivity';
import * as errorCodeToToast from './errorCodeToToast';
import { secondsToHms } from './secondsToHms';
import { getCampaignDateRange } from './getCampaignDateRange';

export const openInvoiceInChargebee = (invoiceId: string) => {
  window.open(`https://${window.env.__APP_CHARGEBEE_SITE__}.chargebee.com/d/invoices/${invoiceId}`, '_blank');
};

export {
  errorCodeToToast,
  createOrderArray,
  addNegativeTimezoneOffset,
  userLatestActivity,
  httpRequest,
  validateUrl,
  getPasswordExpirationData,
  getRoleNameElement,
  sleep,
  useTypedSelector,
  createNetworkErrorObject,
  getLocationPaginationState,
  getUtcDate,
  insertTrailingZero,
  formatNoteDate,
  formatTableTimestamp,
  formatTableTimestampWithHours,
  formatLpiExpressDate,
  formatPublishDate,
  isPrintProject,
  isEmailProject,
  isStory,
  closeFullscreen,
  openFullscreen,
  limitString,
  toCamelCase,
  timestampToTime,
  timeToTimestamp,
  getBase64,
  trimHtml,
  secondsToHms,
  getCampaignDateRange
};
