import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import WarningIcon from '@material-ui/icons/Warning';
import {
  DoAppInvoiceData,
  DoAppInvoiceDateRange,
  DoAppMerchant,
  Invoice
} from '../../../../../store/DoApp/Merchants/types';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong, StrongDiscounted } from '../../../../Shared/StyledComponents';
import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
// import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DoAppGenerateInvoiceWindow from './DoAppGenerateInvoice';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';

type DoAppInvoicePreviewWindowProps = {
  open: boolean;
  merchant: DoAppMerchant | null;
  onCloseClick: () => void;
  onFormSubmit?: () => void;
  fullScreenOnMobile: boolean;
};

const DoAppInvoicePreviewWindow: FunctionComponent<DoAppInvoicePreviewWindowProps> = ({
  merchant,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [merchantCredits, setMerchantCredits] = useState<DoAppInvoiceData['credits']>({
    incentive: 0,
    promotional: 0
  });
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<DoAppInvoiceDateRange>(DoAppInvoiceDateRange.THIS_MONTH);
  const [customDateFrom, setCustomDateFrom] = useState<Date | null>(null);
  const [customDateTo, setCustomDateTo] = useState<Date | null>(null);
  const [generateInvoiceWindowOpen, onGenerateInvoiceWindowOpen, onGenerateInvoiceWindowClose] = useOpenHandler();

  useEffect(() => {
    if (open && merchant) {
      fetchInvoiceData(dateRange);
    }
  }, [open, dateRange]);

  const fetchInvoiceData = async (dateRange: DoAppInvoiceDateRange) => {
    if (!merchant) return;

    try {
      setLoading(true);
      const res = await doAppMerchantsOperations.getInvoiceResources(merchant.id, dateRange);
      setInvoice(res.data.invoice);
      setMerchantCredits(res.data.credits);
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={
        <>
          {t('pages.doApp.merchants.windows.invoicePreview.header')}:&nbsp;
          {merchant && (
            <Typography variant="inherit" style={{ fontStyle: 'italic' }}>
              {merchant.name}
            </Typography>
          )}
        </>
      }
      hideActionButtons
      onCloseClick={onCloseClick}
      fullWidth
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={2}>
        <div>
          <FormControl variant="outlined" size="small">
            <Select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value as DoAppInvoiceDateRange)}
              variant="outlined"
            >
              <MenuItem value={DoAppInvoiceDateRange.THIS_MONTH}>
                {t('pages.doApp.merchants.windows.invoicePreview.thisMonth')}
              </MenuItem>
              <MenuItem value={DoAppInvoiceDateRange.LAST_MONTH}>
                {t('pages.doApp.merchants.windows.invoicePreview.lastMonth')}
              </MenuItem>
              {/* <MenuItem value={DoAppInvoiceDateRange.CUSTOM}>
                {t('pages.doApp.merchants.windows.invoicePreview.customDateRange')}
              </MenuItem> */}
            </Select>
          </FormControl>
          {dateRange === DoAppInvoiceDateRange.CUSTOM && (
            <Box display="flex" alignItems="center" mt={2}>
              <DatePicker
                selected={customDateFrom}
                onChange={(date) => setCustomDateFrom(date)}
                selectsStart
                startDate={customDateFrom}
                endDate={customDateTo}
                customInput={
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={t('pages.doApp.merchants.windows.invoicePreview.selectDateFrom')}
                    size="small"
                  />
                }
              />
              &nbsp;
              <DatePicker
                selected={customDateTo}
                onChange={(date) => setCustomDateTo(date)}
                selectsEnd
                startDate={customDateFrom}
                endDate={customDateTo}
                customInput={
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={t('pages.doApp.merchants.windows.invoicePreview.selectDateTo')}
                    size="small"
                  />
                }
              />
            </Box>
          )}
        </div>
        {invoice && invoice.total > 0 && merchant && merchant.paymentMethodActive && (
          <Button onClick={onGenerateInvoiceWindowOpen} variant="contained" color="primary">
            {t('pages.doApp.merchants.windows.invoicePreview.generate')}
          </Button>
        )}
      </Box>
      <Divider />
      {!loading && merchant && !merchant.paymentMethodActive && (
        <InfoBox type="warning">
          <WarningIcon />{' '}
          <Typography variant="caption" style={{ verticalAlign: 'super' }}>
            {t('pages.doApp.merchants.windows.invoice.merchantHasNoPaymentMethod')}
          </Typography>
        </InfoBox>
      )}
      {!loading && invoice && invoice.lineItems.length === 0 && (
        <InfoBox type="success">
          <DoneAllIcon />{' '}
          <Typography variant="caption" style={{ verticalAlign: 'super' }}>
            {t('pages.doApp.merchants.windows.invoice.noCharges')}
          </Typography>
        </InfoBox>
      )}
      {!loading && invoice && (
        <Row>
          <InfoBox type="outlinedInfo">
            <Typography variant="h6">{t('pages.doApp.merchants.windows.invoicePreview.availableCredits')}:</Typography>
            <Typography variant="body2">
              {t('pages.myOrganization.merchant.incentiveCredits')}:{' '}
              <StrongDiscounted discounted={invoice && invoice.usedCredits.incentive > 0}>
                ${(merchantCredits.incentive / 100).toFixed(2)}
              </StrongDiscounted>
              {invoice && invoice.usedCredits.incentive > 0 && (
                <>
                  &nbsp;
                  <Strong>{((merchantCredits.incentive - invoice.usedCredits.incentive) / 100).toFixed(2)}</Strong>
                </>
              )}
            </Typography>
            <Typography variant="body2">
              {t('pages.myOrganization.merchant.promotionalCredits')}:{' '}
              <StrongDiscounted discounted={invoice && invoice.usedCredits.promotional > 0}>
                ${(merchantCredits.promotional / 100).toFixed(2)}
              </StrongDiscounted>
              {invoice && invoice.usedCredits.promotional > 0 && (
                <>
                  &nbsp;
                  <Strong>{((merchantCredits.promotional - invoice.usedCredits.promotional) / 100).toFixed(2)}</Strong>
                </>
              )}
            </Typography>
          </InfoBox>
        </Row>
      )}
      {!loading && invoice && invoice.lineItems.length > 0 && (
        <Table size="small" style={{ marginTop: 15, marginBottom: 15 }}>
          <TableHead style={{ backgroundColor: '#f6f6f6' }}>
            <TableRow>
              <TableCell>
                <Typography variant="h6">{t('pages.doApp.merchants.windows.invoice.description')}</Typography>
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <Typography variant="h6">{t('pages.doApp.merchants.windows.invoice.amount')}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.lineItems.length > 0 && (
              <>
                {invoice.lineItems.map((lineItem, index) => (
                  <TableRow key={`line-item-${index}`}>
                    <TableCell>
                      <Typography variant="body2">{lineItem.description}</Typography>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                      <Typography variant="body2">
                        {lineItem.discountAmount > 0 && (
                          <>
                            <span style={{ textDecoration: 'line-through', color: 'rgba(0, 0, 0, 0.5)' }}>
                              ${(lineItem.amount / 100).toFixed(2)}
                            </span>
                            &nbsp;
                            <span>${((lineItem.amount - lineItem.discountAmount) / 100).toFixed(2)}</span>
                          </>
                        )}
                        {lineItem.discountAmount <= 0 && <span>${(lineItem.amount / 100).toFixed(2)}</span>}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}

            {/* Total Row */}
            <TableRow>
              <TableCell style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <Typography variant="h6">
                  <Strong>{t('pages.doApp.merchants.windows.invoice.total')}</Strong>
                </Typography>
              </TableCell>
              <TableCell
                style={{ whiteSpace: 'nowrap', textAlign: 'right', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
              >
                <Typography variant="h6">
                  <StrongDiscounted discounted={invoice.discountedAmount > 0}>
                    ${(invoice.total / 100).toFixed(2)}
                  </StrongDiscounted>
                  &nbsp;
                  {invoice.discountedAmount > 0 && (
                    <>
                      <StrongDiscounted discounted={false}>
                        ${((invoice.total - invoice.discountedAmount) / 100).toFixed(2)}
                      </StrongDiscounted>
                      &nbsp;
                      <Typography variant="body2">
                        <Strong>
                          {t('pages.doApp.merchants.windows.invoice.discounted')}: $
                          {(invoice.discountedAmount / 100).toFixed(2)}
                        </Strong>
                      </Typography>
                    </>
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {loading && <Row>{t('pages.doApp.merchants.windows.invoice.loading')}</Row>}
      {merchant && (
        <DoAppGenerateInvoiceWindow
          open={generateInvoiceWindowOpen}
          merchantId={merchant.id}
          invoiceDateRange={dateRange}
          onCloseClick={() => {
            fetchInvoiceData(dateRange);
            onGenerateInvoiceWindowClose();
          }}
          fullScreenOnMobile={false}
        />
      )}
    </ModalConfirmationWindow>
  );
};

export default DoAppInvoicePreviewWindow;
