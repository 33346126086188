import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import { IndexDoAppSubscribersRequestParams } from './types';

export const index = (settings: RequestSettings<IndexDoAppSubscribersRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/subscribers?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const updateCredits = (settings: RequestSettings<{ id: string }, { credits: number }>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/do-app/v1/subscribers/:id'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const verifySubscriber = (settings: RequestSettings<{ verificationToken: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/do-app/v1/subscribers/verify/:verificationToken'
  },
  params: settings.requestParams
});

export const removeSubscriber = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/do-app/v1/subscribers/:id'
  },
  params: settings.requestParams
});

export const removeSubscriberDeviceId = (
  settings: RequestSettings<{ id: string; deviceId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/do-app/v1/subscribers/:id/device/:deviceId'
  },
  params: settings.requestParams
});
