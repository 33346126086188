import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoResultsFound, PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import { RouteComponentProps } from 'react-router';
import { adminAdsOperations } from '../../../../store/AdminAds';
import { AdBanner, Advertiser } from '../../../../store/AdminAds/types';
import { adminOrganizationsOperations } from '../../../../store/AdminOrganizations';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from 'styled-components';
import Loader from '../../../Shared/Loading/Loader';
import EmailTemplateAdsAssignmentPreview from '../Ads/Partials/EmailTemplateAdsAssignmentPreview';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { AdminEmailTemplate } from '../../../../store/AdminEmailTemplates/types';

type AdminOrganizationsDigitalAdsAssignmentPreviewPageProps = RouteComponentProps<{ organizationId: string }> & {};

const AdminOrganizationsDigitalAdsAssignmentPreviewPage: FunctionComponent<AdminOrganizationsDigitalAdsAssignmentPreviewPageProps> = ({
  match
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [organization, setOrganization] = useState<AdminOrganization | null>(null);
  const [banners, setBanners] = useState<AdBanner[]>([]);
  const [emailTemplates, setEmailTemplates] = useState<AdminEmailTemplate[]>([]);
  const [organizations, setOrganizations] = useState<AdminOrganization[]>([]);
  const [houseAdsAdvertiser, setHouseAdsAdvertiser] = useState<Advertiser | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const bannersResponse = await adminAdsOperations.indexOrganizationBanners(match.params.organizationId);
      setOrganization(bannersResponse.organization);
      setBanners(bannersResponse.data);
      setEmailTemplates(bannersResponse.emailTemplates);

      if (!houseAdsAdvertiser) {
        const houseAds = await adminAdsOperations.indexHouseAds();
        setHouseAdsAdvertiser(houseAds.advertiser);
      }

      if (!organizations.length) {
        const organizationsResponse = await adminOrganizationsOperations.getAll();
        setOrganizations(organizationsResponse.data);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [match.params.organizationId]);

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const organizationSelector = (
    <Autocomplete
      onChange={(event, value) => {
        if (value?.id) {
          dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS_ASSIGNMENT(value.id)));
        }
      }}
      value={{ id: match.params.organizationId, label: organization?.name || '' }}
      size="small"
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" />}
      options={organizations.map((org) => ({ label: org.name, id: org.id }))}
      getOptionLabel={(option) => option.label}
    />
  );

  const getActionButtons = () => {
    return [
      {
        label: t('pages.adminAds.openAdsManagement'),
        icon: <PhotoLibraryIcon />,
        onClick: () =>
          organization &&
          dispatch(
            push(
              linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS(match.params.organizationId, organization.accountNumber)
            )
          )
      }
    ];
  };

  return (
    <PageContainer>
      <PageHeader
        leftActionButtons={[backButton]}
        title={
          <TitleWrapper>
            <div>{t('pages.adminOrganizations.titleAdsAssignmentPreview')}</div>{' '}
            <div>{organization && organizationSelector}</div>
          </TitleWrapper>
        }
        rightActionButtons={getActionButtons()}
      />
      <PageContent>
        {loading && <Loader />}
        {!loading && emailTemplates.length <= 0 && (
          <NoResultsFound>{t('pages.adminOrganizations.noMatchingTemplates')}</NoResultsFound>
        )}
        {!loading &&
          organization?.advertiserId &&
          emailTemplates.length > 0 &&
          emailTemplates.map((emailTemplate) => (
            <EmailTemplateAdsAssignmentPreview
              key={emailTemplate.id}
              emailTemplate={emailTemplate}
              banners={banners}
              houseAdsAdvertiser={houseAdsAdvertiser}
            />
          ))}
      </PageContent>
    </PageContainer>
  );
};

const TitleWrapper = styled.div`
  display: flex;

  > div:first-child {
    margin-right: 1em;
  }
  > div:last-child {
    flex-grow: 1;
  }
`;

export default AdminOrganizationsDigitalAdsAssignmentPreviewPage;
