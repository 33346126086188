import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useTypedSelector } from '../../../../../utils';
import { usePaginationWatch } from '../../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../../Shared/DataTable/useTableSearchWatch';
import { PageContainer, PageContent } from '../../../PageStyledComponents';
import PageHeader from '../../../../Shared/Layout/PageHeader';
import DataTable from '../../../../Shared/DataTable/DataTable';
import { useDispatch } from 'react-redux';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';
import { DoAppMerchant, ReferralCodeStatus } from '../../../../../store/DoApp/Merchants/types';
import { TableSearchStoreModule } from '../../../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../../../store/Pagination/types';
import { Strong } from '../../../../Shared/StyledComponents';
import { Box, Button, IconButton, Tooltip, useTheme } from '@material-ui/core';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import MerchantRewardsWindow from '../../../MyOrganization/Windows/MerchantRewardsWindow';
import DoAppAddIncentiveCredits from '../windows/DoAppAddIncentiveCredits';
import DoAppInvoicePreviewWindow from '../windows/DoAppInvoicePreview';
import DoAppMerchantReferralCodes from '../windows/DoAppMerchantReferralCodes';
import Toast from '../../../../Shared/Toast/Toast';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import HistoryIcon from '@material-ui/icons/History';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import linksConstants from '../../../../../config/app/linksConstants';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';

type DoAppMerchantsPageProps = {};

const DoAppMerchantsPage: FunctionComponent<DoAppMerchantsPageProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const doAppMerchants = useTypedSelector((state) => state.doAppMerchants.data);
  const isFailed = useTypedSelector((state) => state.doAppMerchants.index.isFailed);
  const isLoading = useTypedSelector((state) => state.doAppMerchants.index.isLoading);
  const pagination = useTypedSelector((state) => state.doAppMerchants.pagination);
  const tableSearch = useTypedSelector((state) => state.doAppMerchants.tableSearch);

  const [activeMerchant, setActiveMerchant] = useState<DoAppMerchant | null>(null);

  usePaginationWatch(pagination, [doAppMerchantsOperations.index]);
  useTableSearchWatch<DoAppMerchant>(tableSearch, [doAppMerchantsOperations.index]);

  const [invoicePreviewWindowOpen, onInvoicePreviewWindowOpen, onInvoicePreviewWindowClose] = useOpenHandler();
  const [incentiveCreditsWindowOpen, onIncentiveCreditsWindowOpen, onIncentiveCreditsWindowClose] = useOpenHandler();
  const [merchantRewardsWindowOpen, onMerchantRewardsWindowOpen, onMerchantRewardsWindowClose] = useOpenHandler();
  const [referralCodesWindowOpen, onReferralCodesWindowOpen, onReferralCodesWindowClose] = useOpenHandler();

  const fetchData = async () => {
    await dispatch(doAppMerchantsOperations.index());
  };

  const handleSubmitCreditsAdd = async (newCredits: number, note?: string) => {
    if (activeMerchant) {
      try {
        await doAppMerchantsOperations.addIncentiveCredits(activeMerchant.id, newCredits, note);
        onIncentiveCreditsWindowClose();
        await fetchData();
        Toast.success(t('pages.doApp.merchants.addSuccess'));
      } catch (error) {
        Toast.error(t('pages.doApp.merchants.addError'));
        console.error('Error adding incentive credits:', error);
      }
    }
  };

  const getActionButtons = () => {
    return [];
  };

  const backButton = {
    onClick: () => dispatch(push(linksConstants.DO_APP.DASHBOARD)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  return (
    <PageContainer>
      <PageHeader
        title={t(`pages.doApp.merchants.title`)}
        leftActionButtons={[backButton]}
        rightActionButtons={getActionButtons()}
      />
      <PageContent>
        <DataTable<DoAppMerchant, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              render: (row) => (
                <>
                  <Strong>{row.name}</Strong>
                  <Box display="flex" alignItems="center">
                    <MailOutlineOutlinedIcon style={{ width: 14, height: 14, marginRight: 4 }} /> {row.billingEmail}
                  </Box>
                </>
              )
            },
            {
              name: 'merchantPortalId',
              sortable: true,
              render: (row) => <>{row.merchantPortalId}</>
            },
            {
              name: 'type',
              sortable: true,
              render: (row) => <>{row.type ? t(`pages.doApp.merchants.type.${row.type}`) : t('table.empty')}</>
            },
            {
              name: 'referralCodes',
              sortable: false,
              render: (row) => (
                <>
                  {row.referralCodes.length === 0 && t('table.empty')}
                  {row.referralCodes.length > 0 && (
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                      <div style={{ textAlign: 'right' }}>
                        <span
                          style={{
                            textDecoration:
                              row.referralCodes[0].status === ReferralCodeStatus.ACTIVE ? 'none' : 'line-through'
                          }}
                        >
                          {row.referralCodes[0].code}
                        </span>
                        {row.referralCodes.length > 1 && (
                          <>
                            <br />
                            <small style={{ fontSize: '90%' }}>
                              {t('pages.doApp.merchants.andXmore', { count: row.referralCodes.length - 1 })}
                            </small>
                          </>
                        )}
                      </div>
                      <Tooltip title={t('pages.doApp.merchants.details').toString()}>
                        <IconButton
                          size="small"
                          disableRipple
                          onClick={() => {
                            setActiveMerchant(row);
                            onReferralCodesWindowOpen();
                          }}
                          style={{ marginLeft: 5 }}
                        >
                          <ListAltOutlinedIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </>
              )
            },
            {
              name: 'incentiveCredits',
              style: { textAlign: 'right' },
              sortable: true,
              render: (row) => (
                <>
                  ${(row.incentiveCredits / 100).toFixed(2)}{' '}
                  <IconButton
                    size="small"
                    disableRipple
                    onClick={() => {
                      setActiveMerchant(row);
                      onMerchantRewardsWindowOpen();
                    }}
                  >
                    <HistoryIcon fontSize="inherit" />
                  </IconButton>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setActiveMerchant(row);
                      onIncentiveCreditsWindowOpen();
                    }}
                    style={{ marginLeft: 5 }}
                    startIcon={<AddOutlinedIcon />}
                  >
                    {t('pages.doApp.merchants.add')}
                  </Button>
                </>
              )
            },
            {
              name: 'paymentMethodActive',
              sortable: true,
              render: (row) => (
                <>
                  {row.paymentMethodActive ? (
                    <Tooltip title={t('pages.doApp.merchants.active').toString()}>
                      <CreditCardOutlinedIcon htmlColor={theme.palette.success.main} />
                    </Tooltip>
                  ) : (
                    t('table.empty')
                  )}
                </>
              )
            },
            {
              name: 'actions',
              sortable: false,
              render: (row) => (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={async () => {
                      setActiveMerchant(row);
                      onInvoicePreviewWindowOpen();
                    }}
                    startIcon={<ReceiptOutlinedIcon />}
                  >
                    {t('pages.doApp.merchants.invoicePreview')}
                  </Button>
                </>
              )
            }
          ]}
          tPath={'pages.doApp.merchants.table'}
          data={doAppMerchants}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.DO_APP_MERCHANTS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.DO_APP_MERCHANTS
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
        <DoAppInvoicePreviewWindow
          open={invoicePreviewWindowOpen}
          merchant={activeMerchant}
          onCloseClick={onInvoicePreviewWindowClose}
          fullScreenOnMobile
        />
        {activeMerchant && (
          <DoAppAddIncentiveCredits
            open={incentiveCreditsWindowOpen}
            merchant={activeMerchant}
            onCloseClick={onIncentiveCreditsWindowClose}
            onSubmit={handleSubmitCreditsAdd}
          />
        )}
        {activeMerchant && merchantRewardsWindowOpen && (
          <MerchantRewardsWindow
            open={merchantRewardsWindowOpen}
            onCloseClick={onMerchantRewardsWindowClose}
            fullScreenOnMobile
            type="incentive"
            organizationMerchant={activeMerchant}
            removeIncentiveCredits
            onRemoveIncentiveCredits={fetchData}
          />
        )}
        {activeMerchant && referralCodesWindowOpen && (
          <DoAppMerchantReferralCodes
            open={referralCodesWindowOpen}
            merchant={doAppMerchants.entities[activeMerchant.id]}
            onCloseClick={onReferralCodesWindowClose}
            onReferralCodeUpdate={fetchData}
            onReferralCodeStatusChange={fetchData}
          />
        )}
      </PageContent>
    </PageContainer>
  );
};

export default DoAppMerchantsPage;
