import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppSubscriber, DoAppSubscriberStatus } from '../../../../../store/DoApp/Subscribers/types';
import { doAppSubscribersOperations } from '../../../../../store/DoApp/Subscribers';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import Toast from '../../../../Shared/Toast/Toast';
import DoAppUpdateSubscriberCredits from '../windows/DoAppUpdateSubscriberCredits';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import { createNetworkErrorObject } from '../../../../../utils';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import UpdateIcon from '@material-ui/icons/Update';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SettingsCellOutlinedIcon from '@material-ui/icons/SettingsCellOutlined';
import DoAppSubscriberDevices from '../windows/DoAppSubscriberDevices';
import DoAppRemoveSubscriberDeviceId from '../windows/DoAppRemoveSubscriberDeviceId';

type DoAppSubscribersListProps = {};

const DoAppSubscribersList = forwardRef<{ refreshSubscribers: () => void }, DoAppSubscribersListProps>((props, ref) => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [subscribers, setSubscribers] = useState<DoAppSubscriber[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [activeSubscriber, setActiveSubscriber] = useState<DoAppSubscriber | null>(null);
  const [activeSubscriberDeviceId, setActiveSubscriberDeviceId] = useState<string | null>(null);
  const [credits, setCredits] = useState<number>(0);
  const [removeSubscriberWindowOpen, onRemoveSubscriberWindowOpen, onRemoveSubscriberWindowClose] = useOpenHandler();
  const [removeDeviceIdWindowOpen, onRemoveDeviceIdWindowOpen, onRemoveDeviceIdWindowClose] = useOpenHandler();
  const [devicesWindowOpen, onDevicesWindowOpen, onDevicesWindowClose] = useOpenHandler();

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const subscribers = await doAppSubscribersOperations.indexRaw(page, 10, search, {});
      setSubscribers(subscribers.data);
      setPagination(subscribers.pagination);
      setLastSearchValue(search);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    refreshSubscribers: () => fetchData(pagination.page, '')
  }));

  useEffect(() => {
    fetchData(1, '');
  }, []);

  const handleOpenDialog = (subscriber: DoAppSubscriber) => {
    setActiveSubscriber(subscriber);
    setCredits(subscriber.credits);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setActiveSubscriber(null);
  };

  const handleSubmitCreditsUpdate = async (newCredits: number) => {
    if (activeSubscriber) {
      try {
        await doAppSubscribersOperations.updateCredits(activeSubscriber.id, newCredits);
        handleCloseDialog();
        fetchData(pagination.page, searchValue);
        Toast.success(t('pages.doApp.subscribers.updateSuccess'));
      } catch (error) {
        Toast.error(t('pages.doApp.subscribers.updateError'));
        console.error('Error updating credits:', error);
      }
    }
  };

  const renderList = () => {
    return (
      <>
        <WidgetTitle
          title={t('pages.doApp.subscribers.title')}
          subtitle={t('pages.doApp.subscribers.subtitle')}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          fetchData={fetchData}
        />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        {fetchError && <WidgetFetchError title={t('pages.doApp.subscribers.fetchError')} />}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('pages.doApp.subscribers.subscriber')}</TableCell>
                <TableCell align="right">{t('common.status')}</TableCell>
                <TableCell align="right">{t('pages.doApp.subscribers.phone')}</TableCell>
                <TableCell align="right">{t('pages.doApp.subscribers.devices')}</TableCell>
                <TableCell align="right">{t('pages.doApp.subscribers.credits')}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscribers.map((subscriber) => (
                <TableRow key={subscriber.id}>
                  <TableCell component="th" scope="row">
                    <Strong>{subscriber.email}</Strong>
                    <Box display="flex" alignItems="center">
                      <ConfirmationNumberOutlinedIcon style={{ width: 14, height: 14, marginRight: 4 }} />{' '}
                      {subscriber.id}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <SubscriberStatus status={subscriber.status}>
                      {t(`pages.doApp.subscribers.status.${subscriber.status}`)}
                    </SubscriberStatus>
                  </TableCell>
                  <TableCell>{subscriber.phone || 'N/A'}</TableCell>
                  <TableCell align="right">
                    {subscriber.deviceId.length === 0 && t('table.empty')}
                    {subscriber.deviceId.length > 0 && (
                      <>
                        {subscriber.deviceId[0]}
                        <Tooltip title={t('pages.doApp.subscribers.removeDeviceId').toString()}>
                          <IconButton
                            size="small"
                            disableRipple
                            onClick={() => {
                              setActiveSubscriber(subscriber);
                              setActiveSubscriberDeviceId(subscriber.deviceId[0]);
                              onRemoveDeviceIdWindowOpen();
                            }}
                          >
                            <DeleteForeverOutlinedIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                        {subscriber.deviceId.length > 1 && (
                          <>
                            <br />
                            <Button
                              variant="text"
                              size="small"
                              color="secondary"
                              onClick={() => {
                                setActiveSubscriber(subscriber);
                                onDevicesWindowOpen();
                              }}
                              startIcon={<SettingsCellOutlinedIcon fontSize="small" />}
                            >
                              {t('pages.doApp.subscribers.showAll')}
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    ${(subscriber.credits / 100).toFixed(2)}
                    <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={() => handleOpenDialog(subscriber)}
                      style={{ marginLeft: 5 }}
                      startIcon={<UpdateIcon />}
                    >
                      {t('pages.doApp.subscribers.update')}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => {
                        setActiveSubscriber(subscriber);
                        onRemoveSubscriberWindowOpen();
                      }}
                      startIcon={<DeleteOutlineOutlinedIcon />}
                    >
                      {t('common.remove')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>

        {activeSubscriber && (
          <DoAppUpdateSubscriberCredits
            open={openDialog}
            credits={credits}
            onCloseClick={handleCloseDialog}
            onSubmit={handleSubmitCreditsUpdate}
          />
        )}
        {activeSubscriber && (
          <DoAppSubscriberDevices
            open={devicesWindowOpen}
            subscriber={activeSubscriber}
            onCloseClick={onDevicesWindowClose}
            onDeviceIdRemove={() => fetchData(pagination.page, searchValue)}
          />
        )}
        {activeSubscriber && (
          <ModalConfirmationWindow
            open={removeSubscriberWindowOpen}
            header={t('pages.doApp.subscribers.removeSubscriberWindow.header')}
            onCloseClick={onRemoveSubscriberWindowClose}
            okButtonText={t('common.remove')}
            onSubmit={async () => {
              if (activeSubscriber) {
                try {
                  await doAppSubscribersOperations.removeSubscriber(activeSubscriber.id);
                  onRemoveSubscriberWindowClose();
                  await fetchData(1, '');
                  Toast.success(t('notifications.common.successOperation'));
                } catch (e) {
                  const error = createNetworkErrorObject(e);
                  if (error.message) {
                    Toast.error(error.message);
                  } else {
                    Toast.error(t('notifications.common.errorOperation'));
                  }
                }
              }
            }}
          >
            <Row>{t('pages.doApp.subscribers.removeSubscriberWindow.content')}</Row>
            <Row>{activeSubscriber.email}</Row>
          </ModalConfirmationWindow>
        )}
        {activeSubscriber && activeSubscriberDeviceId && (
          <DoAppRemoveSubscriberDeviceId
            open={removeDeviceIdWindowOpen}
            subscriber={activeSubscriber}
            deviceId={activeSubscriberDeviceId}
            onCloseClick={onRemoveDeviceIdWindowClose}
            onSubmit={() => fetchData(pagination.page, searchValue)}
          />
        )}
      </>
    );
  };

  return renderList();
});

const SubscriberStatus = withTheme(styled.span<{ status: DoAppSubscriberStatus }>`
  color: ${(props) => {
    switch (props.status) {
      case DoAppSubscriberStatus.ACTIVE:
        return props.theme.palette.success.main;
      case DoAppSubscriberStatus.NOT_VERIFIED:
        return props.theme.palette.error.main;
      case DoAppSubscriberStatus.INITIALLY_VERIFIED:
        return props.theme.palette.warning.main;
      default:
        return 'black';
    }
  }};
`);

export default DoAppSubscribersList;
