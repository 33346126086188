import AdminOrganizationsPage from './AdminOrganizationsPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';
import AdminOrganizationsDigitalAdsPage from './AdminOrganizationsDigitalAdsPage';
import AdminOrganizationsDigitalAdsAssignmentPreviewPage from './AdminOrganizationsDigitalAdsAssignmentPreviewPage';
import AdminOrganizationEditPage from './AdminOrganizationsEditPage';
import AdminOrganizationsUsersPage from './AdminOrganizationsUsersPage';
import AdminOrganizationCreatePage from './AdminOrganizationsCreatePage';

const AdminOrganizationsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX,
    exact: true,
    name: 'organizations',
    public: false,
    show: true,
    component: AdminOrganizationsPage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ORGANIZATIONS.USERS(':organizationId'),
    exact: false,
    name: 'organizationUsers',
    public: false,
    show: true,
    component: AdminOrganizationsUsersPage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ORGANIZATIONS.EDIT(':organizationId'),
    exact: false,
    name: 'organizationEdit',
    public: false,
    show: true,
    component: AdminOrganizationEditPage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ORGANIZATIONS.CREATE,
    exact: false,
    name: 'organizationCreate',
    public: false,
    show: true,
    component: AdminOrganizationCreatePage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS(':organizationId', ':accountNumber'),
    exact: false,
    name: 'organizationAds',
    public: false,
    show: true,
    component: AdminOrganizationsDigitalAdsPage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS_ASSIGNMENT(':organizationId'),
    exact: false,
    name: 'organizationAdsAssignment',
    public: false,
    show: true,
    component: AdminOrganizationsDigitalAdsAssignmentPreviewPage,
    scope: []
  }
];

export default AdminOrganizationsRoutes;
