import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DoAppInvoiceDateRange } from '../../../../../store/DoApp/Merchants/types';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import Toast from '../../../../Shared/Toast/Toast';
import 'react-datepicker/dist/react-datepicker.css';
import { Alert } from '@material-ui/lab';
import { Box, Button } from '@material-ui/core';
import { openInvoiceInChargebee } from '../../../../../utils';
import { createNetworkErrorObject } from '../../../../../utils';

type DoAppGenerateInvoiceWindowProps = {
  open: boolean;
  merchantId: string;
  invoiceDateRange: DoAppInvoiceDateRange;
  onCloseClick: () => void;
  onFormSubmit?: () => void;
  fullScreenOnMobile: boolean;
};

const DoAppGenerateInvoiceWindow: FunctionComponent<DoAppGenerateInvoiceWindowProps> = ({
  merchantId,
  invoiceDateRange,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [generating, setGenerating] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setInvoiceId(null);
      setError(false);
    }
  }, [open]);

  const createInvoice = async () => {
    setGenerating(true);
    setError(false);
    try {
      const res = await doAppMerchantsOperations.createInvoice(merchantId, invoiceDateRange);
      if (res.data.invoiceId) {
        setInvoiceId(res.data.invoiceId);
      } else {
        setError(true);
      }
    } catch (e) {
      const error = createNetworkErrorObject(e);
      Toast.error(error.message);
    } finally {
      setGenerating(false);
    }
  };

  const handleSubmit = () => {
    createInvoice();
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.merchants.windows.generateInvoice.header')}
      okButtonText={
        generating
          ? t('pages.doApp.merchants.windows.generateInvoice.generating')
          : error
          ? t('pages.doApp.merchants.windows.generateInvoice.retry')
          : t('common.yesIConfirm')
      }
      disableSubmitButton={generating}
      hideActionButtons={invoiceId !== null}
      onCloseClick={onCloseClick}
      onSubmit={handleSubmit}
      fullWidth
    >
      <Row>
        {generating && (
          <Alert severity="info">
            <Strong>{t('pages.doApp.merchants.windows.generateInvoice.generatingContent')}</Strong>
          </Alert>
        )}
        {invoiceId && !error && !generating && (
          <>
            <Alert severity="success">
              <Strong>{t('pages.doApp.merchants.windows.generateInvoice.generationSuccess')}</Strong>
            </Alert>
            <Box textAlign="center" width="100%" mt={1}>
              <Button variant="contained" color="secondary" onClick={() => openInvoiceInChargebee(invoiceId)}>
                {t('pages.doApp.merchants.windows.generateInvoice.openInChargebee')}
              </Button>
            </Box>
          </>
        )}
        {!invoiceId && !error && !generating && (
          <Alert severity="warning">
            <Strong>{t('pages.doApp.merchants.windows.generateInvoice.content')}</Strong>
          </Alert>
        )}
        {error && !generating && (
          <Alert severity="error">
            <Strong>{t('pages.doApp.merchants.windows.generateInvoice.errorContent')}</Strong>
          </Alert>
        )}
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DoAppGenerateInvoiceWindow;
