import React, { FunctionComponent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { PageContainer, PageContent, PageContentPaper } from '../../../PageStyledComponents';
import PageHeader from '../../../../Shared/Layout/PageHeader';
import DoAppPromotionsList from '../Widgets/DoAppPromotionsList';
import DoAppPromotionClaimsList from '../Widgets/DoAppPromotionClaimsList';
import DoAppCreditTransactionsList from '../Widgets/DoAppCreditTransactionsList';
import DoAppSubscribersList from '../Widgets/DoAppSubscribersList';
import styled from 'styled-components';
import DoAppSponsorsList from '../Widgets/DoAppSponsorsList';
import DoAppRunningPromotionsList from '../Widgets/DoAppRunningPromotionsList';
import DoAppRunningPromotionReportsList from '../Widgets/DoAppRunningPromotionReportsList';
import linksConstants from '../../../../../config/app/linksConstants';
import { Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';

type DoAppDashboardPageProps = {};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderLeft: '10px solid #873ae2',
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: '0px 4px 20px rgba(135, 58, 226, 0.4)'
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '16px'
    }
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

const DoAppDashboardPage: FunctionComponent<DoAppDashboardPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const subscribersListRef = useRef<{ refreshSubscribers: () => void }>(null);

  const handleTransactionCreated = () => {
    if (subscribersListRef.current) {
      subscribersListRef.current.refreshSubscribers();
    }
  };

  return (
    <PageContainer>
      <PageHeader title={t(`pages.doApp.dashboard.title`)} />
      <PageContent>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={12}>
            <Card onClick={() => dispatch(push(linksConstants.DO_APP.MERCHANTS.INDEX))} className={classes.root}>
              <CardContent className={classes.content}>
                <StorefrontOutlinedIcon />
                <Typography variant="button">{t('pages.doApp.merchants.title')}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <PageContentPaper>
          <DoAppPromotionsList />
        </PageContentPaper>
        <PageContentPaper>
          <DoAppSubscribersList ref={subscribersListRef} />
        </PageContentPaper>
        <MasonryGrid>
          <MasonryItem>
            <PageContentPaper style={{ marginTop: 0 }}>
              <DoAppPromotionClaimsList />
            </PageContentPaper>
          </MasonryItem>
          <MasonryItem>
            <PageContentPaper>
              <DoAppCreditTransactionsList onTransactionCreated={handleTransactionCreated} />
            </PageContentPaper>
          </MasonryItem>
          <MasonryItem>
            <PageContentPaper>
              <DoAppSponsorsList />
            </PageContentPaper>
          </MasonryItem>
          <MasonryItem>
            <PageContentPaper>
              <DoAppRunningPromotionsList />
            </PageContentPaper>
          </MasonryItem>
          <MasonryItem>
            <PageContentPaper>
              <DoAppRunningPromotionReportsList />
            </PageContentPaper>
          </MasonryItem>
        </MasonryGrid>
      </PageContent>
    </PageContainer>
  );
};

export default DoAppDashboardPage;

const MasonryGrid = styled.div`
  column-count: 2; /* Number of masonry columns */
  column-gap: 16px; /* Space between the columns */
`;

const MasonryItem = styled.div`
  break-inside: avoid;
  margin-bottom: 16px; /* Space between the items vertically */
`;
