import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { WidgetSearchInput } from './WidgetSearchInput';
import { useTranslation } from 'react-i18next';

type WidgetTitleProps = {
  title: string;
  subtitle?: string;
  searchValue?: string;
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
  fetchData?: (page: number, search: string) => Promise<void>;
};

export const WidgetTitle = (props: WidgetTitleProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <div>
        <Typography variant="h6">{props.title}</Typography>
        {props.subtitle && (
          <Typography variant="caption" gutterBottom style={{ fontStyle: 'italic' }}>
            {props.subtitle}
          </Typography>
        )}
      </div>
      {props.searchValue !== undefined && props.setSearchValue && props.fetchData && (
        <WidgetSearchInput
          placeholder={`${t('common.search')}...`}
          value={props.searchValue}
          setSearchValue={props.setSearchValue}
          handleSearch={props.fetchData}
        />
      )}
    </Box>
  );
};
