import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoResultsFound, PageContainer } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import { RouteComponentProps } from 'react-router';
import Loader from '../../../Shared/Loading/Loader';
import { AdBanner, AdBannerType, AdCampaign } from '../../../../store/AdminAds/types';
import { adminAdsOperations } from '../../../../store/AdminAds';
import AdminAdsTable from '../Ads/AdminAdsTable';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminCreateAdBannerWindow from '../Ads/Windows/AdBanner/AdminCreateAdBannerWindow';
import AdminUpdateAdBannerWindow from '../Ads/Windows/AdBanner/AdminUpdateAdBannerWindow';
import AdminDeleteAdBannerWindow from '../Ads/Windows/AdBanner/AdminDeleteAdBannerWindow';
import { hasPermission, isSystemOwner } from '../../../../utils/permissions';
import { createNetworkErrorObject, useTypedSelector } from '../../../../utils';
import AdminMobileAdCampaignsTable from '../Ads/AdminMobileAdCampaignsTable';
import AdminListZonesWindow from '../Ads/Windows/AdBanner/AdminListZonesWindow';
import { AppBar, Box, Button, Grid, Paper, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import styled from 'styled-components';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import MobileFriendlyOutlinedIcon from '@material-ui/icons/MobileFriendlyOutlined';
import AdminShowOrganizationAdsAssignmentsWindow from './Windows/AdminShowOrganizationAdsAssignmentsWindow';
import { doAppMerchantsOperations } from '../../../../store/DoApp/Merchants';
import { DoAppMerchant } from '../../../../store/DoApp/Merchants/types';
import Toast from '../../../Shared/Toast/Toast';
import { Alert, AlertTitle } from '@material-ui/lab';
import { validateEmail } from '../../../../utils/validators';
import { adminOrganizationsOperations } from '../../../../store/AdminOrganizations';

type AdminOrganizationsDigitalAdsPageProps = RouteComponentProps<{
  organizationId: string;
  accountNumber: string;
}> & {};

interface TabPanelProps {
  children?: React.ReactNode;
  index: OrganizationDigitalAdsTabs;
  value: OrganizationDigitalAdsTabs;
}

enum OrganizationDigitalAdsTabs {
  EMAIL,
  MOBILE
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`organization-ads-tabpanel-${index}`}
      aria-labelledby={`organization-ads-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: OrganizationDigitalAdsTabs) {
  return {
    id: `organization-ads-tab-${index}`,
    'aria-controls': `organization-ads-tabpanel-${index}`
  };
}

const AdminOrganizationsDigitalAdsPage: FunctionComponent<AdminOrganizationsDigitalAdsPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const authState = useTypedSelector((state) => state.auth);

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [organization, setOrganization] = useState<AdminOrganization | null>(null);
  const [banners, setBanners] = useState<AdBanner[]>([]);
  const [mobileCampaigns, setMobileCampaigns] = useState<AdCampaign[]>([]);
  const [error, setError] = useState<boolean>(false);

  const [activeBanner, setActiveBanner] = useState<AdBanner | null>(null);
  const [activeBannerType, setActiveBannerType] = useState<AdBannerType.EMAIL | AdBannerType.MOBILE_MERCHANT>(
    AdBannerType.EMAIL
  );
  const [merchantData, setMerchantData] = useState<DoAppMerchant | null>(null);
  const [missingMerchantData, setMissingMerchantData] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [billingEmailError, setBillingEmailError] = useState(false);

  const [updateAdBannerWindowOpen, onUpdateAdBannerWindowOpen, onUpdateAdBannerWindowClose] = useOpenHandler();
  const [deleteAdBannerWindowOpen, onDeleteAdBannerWindowOpen, onDeleteAdBannerWindowClose] = useOpenHandler();
  const [createAdBannerWindowOpen, onCreateAdBannerWindowOpen, onCreateAdBannerWindowClose] = useOpenHandler();
  const [listZonesWindowOpen, onListZonesWindowOpen, onListZonesWindowClose] = useOpenHandler();
  const [
    organizationAdsAssignmentsWindowOpen,
    onOrganizationAdsAssignmentsWindowOpen,
    onOrganizationAdsAssignmentsWindowClose
  ] = useOpenHandler();

  const [tabValue, setTabValue] = React.useState<OrganizationDigitalAdsTabs>(OrganizationDigitalAdsTabs.EMAIL);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: OrganizationDigitalAdsTabs) => {
    setTabValue(newValue);
  };

  const fetchData = async (initialLoad?: boolean) => {
    if (initialLoad) {
      setLoading(true);
    }
    try {
      setError(false);

      const bannersResponse = await adminAdsOperations.indexOrganizationBanners(match.params.organizationId);
      setBanners(bannersResponse.data);
      setOrganization(bannersResponse.organization);

      if (!bannersResponse.organization.isDistrictOrRegion) {
        await fetchMerchantData();
      }

      if (bannersResponse.organization.advertiserId) {
        const mobileCampaignsResponse = await adminAdsOperations.indexAdvertiserBanners(
          bannersResponse.organization.advertiserId,
          AdBannerType.MOBILE_MERCHANT
        );
        setMobileCampaigns(mobileCampaignsResponse.data);
      }

      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  const fetchMerchantData = async () => {
    try {
      const merchantResponse = await doAppMerchantsOperations.getOrganizationMerchantByAccountNumber(
        match.params.accountNumber
      );
      setMissingMerchantData(false);
      setMerchantData(merchantResponse.data);

      if (merchantResponse.data.advertiserId === null) {
        await updateMerchantAdvertiserId(merchantResponse.data.id);
      }
    } catch (error) {
      const networkError = createNetworkErrorObject(error);
      if (networkError.statusCode === 404) {
        setMissingMerchantData(true);
      } else {
        Toast.error(t('notifications.adminOrganizations.cannotFetchMerchantData'));
      }
    }
  };

  const updateMerchantAdvertiserId = async (merchantId: string) => {
    try {
      const organizationDataResponse = await adminOrganizationsOperations.show(match.params.organizationId);
      if (organizationDataResponse.advertiserId) {
        await doAppMerchantsOperations.updateMerchant(merchantId, {
          advertiserId: organizationDataResponse.advertiserId
        });
        return organizationDataResponse.advertiserId;
      }
    } catch (error) {
      Toast.error(t('notifications.adminOrganizations.cannotUpdateMerchantAdvertiserId'));
    }
  };

  const handleSaveMerchantData = async () => {
    if (!email || !validateEmail(email)) {
      setEmailError(true);
      return;
    }

    if (!billingEmail || !validateEmail(billingEmail)) {
      setBillingEmailError(true);
      return;
    }

    try {
      setSaving(true);
      if (organization) {
        const merchantDataBody = {
          id: organization.id,
          name: organization.name,
          merchantPortalId: organization.accountNumber,
          email,
          billingEmail,
          advertiserId: organization.advertiserId,
          isAdvertiserOnly: true,
          ...(organization.region != null && { region: organization.region }),
          ...(organization.district != null && { district: organization.district })
        };

        await doAppMerchantsOperations.createMerchant(merchantDataBody);
        await fetchMerchantData();
        Toast.success(t('notifications.adminOrganizations.merchantDataUpdated'));
      }
    } catch (error) {
      Toast.error(t('notifications.adminOrganizations.merchantDataUpdateFailed'));
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    fetchData(true);
  }, [match.params.organizationId]);

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  return (
    <PageContainer>
      {error && <NoResultsFound>{t('pages.adminOrganizations.errorOnAdsFetch')}</NoResultsFound>}
      {loading && <Loader />}
      {!loading && organization && (
        <>
          <PageHeader
            title={`${organization.name} - ${t('pages.adminOrganizations.titleAds')}`}
            leftActionButtons={[backButton]}
          />
          {missingMerchantData && (
            <Paper style={{ padding: '15px', marginBottom: '20px', marginTop: '10px' }}>
              <Alert severity="warning">
                <AlertTitle>{t('pages.adminOrganizations.merchant.missingMerchantData')}</AlertTitle>
                {t('pages.adminOrganizations.merchant.missingMerchantDataContent')}
                {!isSystemOwner(authState) &&
                  ` ${t('pages.adminOrganizations.merchant.missingMerchantDataFormDisabled')}`}
              </Alert>
              <Box display="flex" alignItems="flex-start" marginTop="15px">
                <TextField
                  label={t('pages.adminOrganizations.merchant.email')}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                  type="email"
                  size="small"
                  variant="outlined"
                  error={emailError}
                  helperText={emailError ? t('pages.adminOrganizations.merchant.invalidEmail') : ''}
                  style={{ minWidth: 260 }}
                  disabled={!isSystemOwner(authState)}
                />
                <TextField
                  label={t('pages.adminOrganizations.merchant.billingEmail')}
                  value={billingEmail}
                  onChange={(e) => {
                    setBillingEmail(e.target.value);
                    setBillingEmailError(false);
                  }}
                  type="email"
                  size="small"
                  variant="outlined"
                  error={billingEmailError}
                  helperText={billingEmailError ? t('pages.adminOrganizations.merchant.invalidEmail') : ''}
                  style={{ minWidth: 260, marginLeft: 10, marginRight: 10 }}
                  disabled={!isSystemOwner(authState)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveMerchantData}
                  disabled={
                    !email || !billingEmail || emailError || billingEmailError || !isSystemOwner(authState) || saving
                  }
                >
                  {saving ? t('common.saving') : t('common.save')}
                </Button>
              </Box>
            </Paper>
          )}
          {merchantData && (
            <Paper style={{ padding: '15px', marginBottom: '20px', marginTop: '10px' }}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2">
                    {t('pages.adminOrganizations.merchant.email')}: <strong>{merchantData.email}</strong>
                  </Typography>
                  <Typography variant="body2">
                    {t('pages.adminOrganizations.merchant.billingEmail')}: <strong>{merchantData.billingEmail}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
                  <Typography variant="body2">
                    {t('pages.adminOrganizations.merchant.advertiserId')}: <strong>{organization.advertiserId}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}
          {organization.advertiserId && (
            <Paper style={{ marginTop: '20px' }}>
              <AppBar
                position="static"
                color="default"
                elevation={0}
                style={{ background: 'none', borderBottom: '1px solid #eee' }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="organization-ads-tabs"
                  indicatorColor="secondary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <StyledTab
                    label={t('pages.adminAds.email')}
                    icon={<EmailOutlinedIcon />}
                    {...a11yProps(OrganizationDigitalAdsTabs.EMAIL)}
                  />
                  <StyledTab
                    label={t('pages.adminAds.mobile')}
                    icon={<MobileFriendlyOutlinedIcon />}
                    {...a11yProps(OrganizationDigitalAdsTabs.MOBILE)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={OrganizationDigitalAdsTabs.EMAIL}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" padding="15px">
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() =>
                      dispatch(
                        push(linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS_ASSIGNMENT(match.params.organizationId))
                      )
                    }
                    style={{ marginRight: '10px' }}
                    disabled={missingMerchantData}
                  >
                    <VisibilityIcon /> {t('pages.adminAds.openAssignments')}
                  </Button>
                  {hasPermission(role, ['adsAdd']) && (
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      onClick={() => {
                        setActiveBannerType(AdBannerType.EMAIL);
                        onCreateAdBannerWindowOpen();
                      }}
                      disabled={missingMerchantData}
                    >
                      <AddIcon /> {t('pages.adminAds.addNewBanner')}
                    </Button>
                  )}
                </Box>
                <AdminAdsTable
                  banners={banners}
                  isLoading={loading}
                  onUpdateAdBanner={(banner) => {
                    setActiveBannerType(AdBannerType.EMAIL);
                    setActiveBanner(banner);
                    onUpdateAdBannerWindowOpen();
                  }}
                  onRemoveAdBanner={(banner) => {
                    setActiveBannerType(AdBannerType.EMAIL);
                    setActiveBanner(banner);
                    onDeleteAdBannerWindowOpen();
                  }}
                  elevation={0}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={OrganizationDigitalAdsTabs.MOBILE}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" padding="15px">
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={onOrganizationAdsAssignmentsWindowOpen}
                    style={{ marginRight: '10px' }}
                    disabled={missingMerchantData}
                  >
                    <VisibilityIcon /> {t('pages.adminAds.openAssignments')}
                  </Button>
                  {hasPermission(role, ['adsAdd']) && (
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      onClick={() => {
                        setActiveBannerType(AdBannerType.MOBILE_MERCHANT);
                        onCreateAdBannerWindowOpen();
                      }}
                      disabled={missingMerchantData}
                    >
                      <AddIcon /> {t('pages.adminAds.addMerchantBanner')}
                    </Button>
                  )}
                </Box>
                <AdminMobileAdCampaignsTable
                  campaigns={mobileCampaigns}
                  isLoading={loading}
                  onUpdateAdBanner={(banner) => {
                    setActiveBannerType(AdBannerType.MOBILE_MERCHANT);
                    setActiveBanner(banner);
                    onUpdateAdBannerWindowOpen();
                  }}
                  onRemoveAdBanner={(banner) => {
                    setActiveBannerType(AdBannerType.MOBILE_MERCHANT);
                    setActiveBanner(banner);
                    onDeleteAdBannerWindowOpen();
                  }}
                  onListZones={(banner) => {
                    setActiveBannerType(AdBannerType.MOBILE_MERCHANT);
                    setActiveBanner(banner);
                    onListZonesWindowOpen();
                  }}
                  elevation={0}
                />
              </TabPanel>
            </Paper>
          )}

          {organization.advertiserId && (
            <AdminCreateAdBannerWindow
              advertiserId={organization.advertiserId}
              organization={organization}
              open={createAdBannerWindowOpen}
              categories={[]}
              onCloseClick={onCreateAdBannerWindowClose}
              fullScreenOnMobile={true}
              showCategories={false}
              onFormSuSubmit={fetchData}
              type={activeBannerType}
            />
          )}
          {organization.advertiserId && (
            <AdminUpdateAdBannerWindow
              advertiserId={organization.advertiserId}
              organization={organization}
              banner={activeBanner}
              categories={[]}
              open={updateAdBannerWindowOpen}
              onCloseClick={onUpdateAdBannerWindowClose}
              fullScreenOnMobile={true}
              showCategories={false}
              onFormSuSubmit={fetchData}
              type={activeBannerType}
            />
          )}
          <AdminDeleteAdBannerWindow
            banner={activeBanner}
            open={deleteAdBannerWindowOpen}
            onCloseClick={onDeleteAdBannerWindowClose}
            fullScreenOnMobile={true}
            onFormSuSubmit={fetchData}
            type={activeBannerType}
          />
          <AdminListZonesWindow
            open={listZonesWindowOpen}
            banner={activeBanner}
            organization={organization}
            onCloseClick={onListZonesWindowClose}
            fullScreenOnMobile={true}
            onSaveSuccess={fetchData}
          />
          <AdminShowOrganizationAdsAssignmentsWindow
            organization={organization}
            open={organizationAdsAssignmentsWindowOpen}
            onCloseClick={onOrganizationAdsAssignmentsWindowClose}
            fullScreenOnMobile
          />
        </>
      )}
    </PageContainer>
  );
};

export default AdminOrganizationsDigitalAdsPage;

const StyledTab = styled(Tab)`
  min-height: 50px;
  text-transform: none;
  font-size: 1rem;
  & .MuiTab-wrapper {
    flex-direction: row;
    .MuiSvgIcon-root {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
`;
