import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { DoAppSubscriber } from '../../../../../store/DoApp/Subscribers/types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip
} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import DoAppRemoveSubscriberDeviceId from './DoAppRemoveSubscriberDeviceId';

type DoAppSubscriberDevicesProps = {
  open: boolean;
  subscriber: DoAppSubscriber;
  onCloseClick: () => void;
  onDeviceIdRemove: () => void;
};

const DoAppSubscriberDevices: FunctionComponent<DoAppSubscriberDevicesProps> = ({
  open,
  subscriber,
  onCloseClick,
  onDeviceIdRemove
}) => {
  const { t } = useTranslation();

  const [activeSubscriberDeviceId, setActiveSubscriberDeviceId] = useState<string | null>(null);
  const [removeDeviceIdWindowOpen, onRemoveDeviceIdWindowOpen, onRemoveDeviceIdWindowClose] = useOpenHandler();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.subscribers.subscriberDevicesWindow.header')}
      onCloseClick={onCloseClick}
      hideActionButtons
    >
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('pages.doApp.subscribers.deviceId')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriber.deviceId.map((id) => (
              <TableRow key={id}>
                <TableCell component="th" scope="row">
                  {id}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title={t('pages.doApp.subscribers.removeDeviceId').toString()}>
                    <IconButton
                      size="small"
                      disableRipple
                      onClick={() => {
                        setActiveSubscriberDeviceId(id);
                        onRemoveDeviceIdWindowOpen();
                      }}
                    >
                      <DeleteForeverOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {subscriber && activeSubscriberDeviceId && (
        <DoAppRemoveSubscriberDeviceId
          open={removeDeviceIdWindowOpen}
          subscriber={subscriber}
          deviceId={activeSubscriberDeviceId}
          onCloseClick={onRemoveDeviceIdWindowClose}
          onSubmit={() => {
            onCloseClick();
            onDeviceIdRemove();
          }}
        />
      )}
    </ModalConfirmationWindow>
  );
};

export default DoAppSubscriberDevices;
