import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Radio, Theme } from '@material-ui/core';
import { FilterHeader } from '../../AdminStyledComponents';
import { AdminProjectsFilters, PublicationDateFilterChangePayload } from '../../../../../store/AdminProjects/types';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import app from '../../../../../config/app/app';

type PublicationDateFilterProps = {
  publicationDateSelectedOption: AdminProjectsFilters['publicationDateSelectedOption'];
  publicationDateExact: AdminProjectsFilters['publicationDateExact'];
  publicationDateRange: AdminProjectsFilters['publicationDateRange'];
  onChange: (payload: PublicationDateFilterChangePayload) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5)
    },
    datePicker: {
      '& .MuiOutlinedInput-inputAdornedEnd': {
        paddingTop: '10.5px',
        paddingBottom: '10.5px'
      }
    }
  })
);

const PublicationDateFilter: FunctionComponent<PublicationDateFilterProps> = ({
  publicationDateSelectedOption,
  publicationDateExact,
  publicationDateRange,
  onChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <FilterHeader>{t('pages.adminProjects.filters.publicationDate')}</FilterHeader>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Radio
          checked={publicationDateSelectedOption === 'exact'}
          onClick={() =>
            onChange({
              publicationDateExact,
              publicationDateRange,
              publicationDateSelectedOption: publicationDateSelectedOption === 'exact' ? null : 'exact'
            })
          }
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format={app.dateFormats.datePickers.date}
            margin="none"
            value={publicationDateExact}
            onChange={(date) =>
              onChange({
                publicationDateExact: date,
                publicationDateRange,
                publicationDateSelectedOption
              })
            }
            className={classes.datePicker}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div>
        <Radio
          checked={publicationDateSelectedOption === 'range'}
          onClick={() => {
            onChange({
              publicationDateExact,
              publicationDateRange,
              publicationDateSelectedOption: publicationDateSelectedOption === 'range' ? null : 'range'
            });
          }}
        />
        {t('pages.adminProjects.filters.dateRange')}
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '42px' }}>From:</div>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format={app.dateFormats.datePickers.date}
            margin="none"
            value={publicationDateRange.from}
            onChange={(date) =>
              onChange({
                publicationDateExact,
                publicationDateRange: {
                  from: date,
                  to: publicationDateRange.to
                },
                publicationDateSelectedOption
              })
            }
            className={classes.datePicker}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '42px' }}>To:</div>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format={app.dateFormats.datePickers.date}
            margin="none"
            value={publicationDateRange.to}
            onChange={(date) =>
              onChange({
                publicationDateExact,
                publicationDateRange: {
                  from: publicationDateRange.from,
                  to: date
                },
                publicationDateSelectedOption
              })
            }
            className={classes.datePicker}
          />
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default PublicationDateFilter;
