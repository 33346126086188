import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Link, List, ListItem, ListItemText } from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppRunningPromotion } from '../../../../../store/DoApp/Reports/types';
import { doAppReportsOperations } from '../../../../../store/DoApp/Reports';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetSearchInput } from '../../../../Shared/ListingWidget/WidgetSearchInput';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import { formatPublishDate, openInvoiceInChargebee } from '../../../../../utils';

type DoAppRunningPromotionsListProps = {};

const DoAppRunningPromotionsList: FunctionComponent<DoAppRunningPromotionsListProps> = () => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [runningPromotions, setRunningPromotions] = useState<DoAppRunningPromotion[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const runningPromotions = await doAppReportsOperations.indexRunningPromotionsRaw(page, 10, search, {});
      setRunningPromotions(runningPromotions.data);
      setPagination(runningPromotions.pagination);
      setLastSearchValue(search);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.key === 'Enter') {
        fetchData(1, searchValue);
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [searchValue]);

  const renderList = () => {
    return (
      <>
        <WidgetTitle title={t('pages.doApp.runningPromotions.title')} />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        <WidgetSearchInput
          placeholder={`${t('common.search')}...`}
          value={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={fetchData}
        />
        {fetchError && <WidgetFetchError title={t('pages.doApp.runningPromotions.fetchError')} />}
        <List>
          {runningPromotions.map((rp) => (
            <React.Fragment key={rp.id}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={rp.merchant}
                  secondary={`${t('pages.doApp.runningPromotions.promotion')}: ${rp.promotion}`}
                />
                <ListItemText
                  primary={
                    <>
                      {t('pages.doApp.runningPromotions.invoice')}:{' '}
                      {rp.invoiceId ? (
                        <Link
                          component="button"
                          color="secondary"
                          variant="body2"
                          onClick={() => rp.invoiceId && openInvoiceInChargebee(rp.invoiceId)}
                          style={{ fontWeight: 'bold' }}
                        >
                          {rp.invoiceId}
                        </Link>
                      ) : (
                        ''
                      )}
                    </>
                  }
                  secondary={`${t('pages.doApp.runningPromotions.date')}: ${formatPublishDate(rp.date)}`}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>
      </>
    );
  };

  return renderList();
};

export default DoAppRunningPromotionsList;
